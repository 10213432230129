import { CreateCategoryResponse, SearchCategoriesRequest, SubscriptionCategoryConfiguration, UpdateCategoryResponse } from 'services/ApiService/InsightBackoffice/InsightBackofficeApiClient';
import { ApiRequest, Reducer } from 'store';

export const DEFAULT_SEARCH_CATEGORIES_FILTERS: SearchCategoriesRequest = {
    skip: 0,
    take: 15,
    onlyActive: true,
};

export const initialState = {
    search: {
        filters: {
            ...DEFAULT_SEARCH_CATEGORIES_FILTERS,
        },
        total: 0,
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]> & {
        filters: SearchCategoriesRequest,
        total: number,
    },
    categories: {} as { readonly [id: number]: ApiRequest },
    creation: {} as { readonly [name: string]: ApiRequest<CreateCategoryResponse> },
    update: {} as { readonly [id: number]: ApiRequest<UpdateCategoryResponse> },
    subscriptionConfigs : {} as {readonly [id: number]: ApiRequest<SubscriptionCategoryConfiguration> },
    deactivate:{} as { readonly[categoryId: number]: boolean },
    reactivate:{} as { readonly[categoryId: number]: boolean },
} as const;

export type CategoriesState = typeof initialState;

export const CategoriesReducer: Reducer<CategoriesState> = (state = initialState, action) => {
    switch (action.type) {
        case '@CATEGORIES/SET_SEARCH_CATEGORIES_SEARCH_TERMS':
            if (state.search.filters.searchTerms?.toLowerCase() === action.payload.searchTerms?.toLowerCase()) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state.search.filters,
                        searchTerms: action.payload.searchTerms,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@CATEGORIES/SET_SEARCH_CATEGORIES_ONLY_ACTIVE':
            if (state.search.filters.onlyActive === action.payload.onlyActive) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state.search.filters,
                        onlyActive: action.payload.onlyActive,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@CATEGORIES/SEARCH_CATEGORIES_CHANGE_PAGE':
            if (state.search.filters.skip === action.payload.skip && state.search.filters.take === action.payload.take) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state.search.filters,
                        skip: action.payload.skip,
                        take: action.payload.take,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@CATEGORIES/SEARCH_CATEGORIES':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: true,
                },
            };

        case '@CATEGORIES/SEARCH_CATEGORIES_SUCCESS':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: false,
                    isFetching: false,
                    data: action.payload.categoryIds,
                    total: action.payload.total,
                },
                categories: createFetchCategoriesState(
                    state,
                    action.payload.categoryIds,
                    {
                        didInvalidate: false,
                        isFetching: false,
                    }),
            };

        case '@CATEGORIES/SEARCH_CATEGORIES_FAILURE':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@CATEGORIES/CREATE_CATEGORY':
            return {
                ...state,
                creation: {
                    ...state.creation,
                    [action.payload.name]: {
                        didInvalidate: true,
                        isFetching: true,
                    },
                },
            };

        case '@CATEGORIES/CREATE_CATEGORY_SUCCESS':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: false,
                },
                creation: {
                    ...state.creation,
                    [action.payload.name]: {
                        didInvalidate: false,
                        isFetching: false,
                        data: action.payload.data,
                    },
                },
            };

        case '@CATEGORIES/CREATE_CATEGORY_FAILURE':
            return {
                ...state,
                creation: {
                    ...state.creation,
                    [action.payload.name]: {
                        didInvalidate: false,
                        isFetching: false,
                    },
                },
            };

        case '@CATEGORIES/FETCH_CATEGORY_SUBSCRIPTION_CONFIG':
            return {
                ...state,
                subscriptionConfigs: {
                    ...state.subscriptionConfigs,
                    [action.payload.categoryId]: {
                        ...state.subscriptionConfigs[action.payload.categoryId],
                        didInvalidate: true,
                        isFetching: true,
                    },
                },
            };

        case '@CATEGORIES/FETCH_CATEGORY_SUBSCRIPTION_CONFIG_SUCCESS':
            return {
                ...state,
                subscriptionConfigs: {
                    ...state.subscriptionConfigs,
                    [action.payload.categoryId]: {
                        ...state.subscriptionConfigs[action.payload.categoryId],
                        didInvalidate: false,
                        isFetching: false,
                        data: action.payload.config,
                    },
                },
            };

        case '@CATEGORIES/FETCH_CATEGORY_SUBSCRIPTION_CONFIG_FAILURE':
            return {
                ...state,
                subscriptionConfigs: {
                    ...state.subscriptionConfigs,
                    [action.payload.categoryId]: {
                        ...state.subscriptionConfigs[action.payload.categoryId],
                        didInvalidate: true,
                        isFetching: false,
                        data: undefined,
                    },
                },
            };

        case '@CATEGORIES/UPDATE_CATEGORY':
            return {
                ...state,
                update: {
                    ...state.update,
                    [action.payload.categoryId]: {
                        didInvalidate: true,
                        isFetching: true,
                    },
                },
            };

        case '@CATEGORIES/UPDATE_CATEGORY_SUCCESS':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: false,
                },
                update: {
                    ...state.update,
                    [action.payload.categoryId]: {
                        didInvalidate: false,
                        isFetching: false,
                        data: action.payload.data,
                    },
                },
                subscriptionConfigs: {
                    ...state.subscriptionConfigs,
                    [action.payload.categoryId]: {
                        ...state.subscriptionConfigs[action.payload.categoryId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@CATEGORIES/UPDATE_CATEGORY_FAILURE':
            return {
                ...state,
                update: {
                    ...state.update,
                    [action.payload.categoryId]: {
                        didInvalidate: false,
                        isFetching: false,
                    },
                },
            };

        case '@CATEGORIES/FETCH_CATEGORY':
            return {
                ...state,
                categories: createFetchCategoriesState(
                    state,
                    [action.payload.categoryId],
                    {
                        didInvalidate: true,
                        isFetching: true,
                    }),
            };

        case '@CATEGORIES/FETCH_CATEGORY_SUCCESS':
            return {
                ...state,
                categories: createFetchCategoriesState(
                    state,
                    [action.payload.categoryId],
                    {
                        didInvalidate: false,
                        isFetching: false,
                    }),
            };

        case '@CATEGORIES/FETCH_CATEGORY_FAILURE':
            return {
                ...state,
                categories: createFetchCategoriesState(
                    state,
                    [action.payload.categoryId],
                    {
                        didInvalidate: true,
                        isFetching: false,
                    }),
            };
        
        case '@CATEGORIES/DEACTIVATE_CATEGORY':
            return {
                ...state,
                deactivate: {
                    ...state.deactivate,
                    [action.payload.categoryId]: true,
                },
            };

        case '@CATEGORIES/DEACTIVATE_CATEGORY_SUCCESS':
            return {
                ...state,
                deactivate: {
                    ...state.deactivate,
                    [action.payload.categoryId]: false,
                },
                categories: createFetchCategoriesState(
                    state,
                    [action.payload.categoryId],
                    {
                        isFetching: false,
                        didInvalidate: true,
                    }),
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@CATEGORIES/DEACTIVATE_CATEGORY_FAILURE':
            return {
                ...state,
                deactivate: {
                    ...state.deactivate,
                    [action.payload.categoryId]: false,
                },
            };

        case '@CATEGORIES/REACTIVATE_CATEGORY':
            return {
                ...state,
                reactivate: {
                    ...state.reactivate,
                    [action.payload.categoryId]: true,
                },
            };

        case '@CATEGORIES/REACTIVATE_CATEGORY_SUCCESS':
            return {
                ...state,
                reactivate: {
                    ...state.reactivate,
                    [action.payload.categoryId]: false,
                },
                categories: createFetchCategoriesState(
                    state,
                    [action.payload.categoryId],
                    {
                        isFetching: false,
                        didInvalidate: true,
                    }),
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@CATEGORIES/REACTIVATE_CATEGORY_FAILURE':
            return {
                ...state,
                reactivate: {
                    ...state.reactivate,
                    [action.payload.categoryId]: false,
                },
            };
        default:
            return state;
    }
};

const createFetchCategoriesState = (state: CategoriesState, categoryIds: number[], apiRequest: ApiRequest) => {
    let stateCategories = {
        ...state.categories,
    };

    for (const categoryId of categoryIds) {
        stateCategories = {
            ...stateCategories,
            [categoryId]: {
                ...state.categories[categoryId],
                ...apiRequest,
            },
        };
    }
    return stateCategories;
};
