import { SearchGroupsRequest } from 'services/ApiService/InsightBackoffice/InsightBackofficeApiClient';
import { ApiRequest, Reducer } from 'store';

export const DEFAULT_SEARCH_GROUPS_FILTERS: SearchGroupsRequest = {
    skip: 0,
    take: 15,
    onlyActive: true,
    onlyResearchGroups: true,
};

export const initialState = {
    search: {
        filters: {
            ...DEFAULT_SEARCH_GROUPS_FILTERS,
        },
        total: 0,
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]> & {
        filters: SearchGroupsRequest,
        total: number,
    },
    groups: {} as { readonly [groupId: number]: ApiRequest },
    creation: {} as { readonly [name: string]: boolean },
    update: {} as { readonly [groupId: number]: boolean },
    deactivate: {} as { readonly [groupId: number]: boolean },
    reactivate: {} as { readonly [groupId: number]: boolean },
    users: {} as { readonly [groupId: number]: ApiRequest<string[]> },
    addUser: {} as { readonly [groupIdAndUser: string]: boolean },
    addGroupsToAnalyst: {} as { readonly [groupIdsAndUser: string]: boolean },
    deactivateUser: {} as { readonly [groupIdAndUser: string]: boolean },
} as const;

export type GroupsState = typeof initialState;

export const GroupsReducer: Reducer<GroupsState> = (state = initialState, action) => {
    switch (action.type) {
        case '@GROUPS/SET_SEARCH_GROUPS_SEARCH_TERMS':
            if (state.search.filters.searchTerms?.toLowerCase() === action.payload.searchTerms?.toLowerCase()) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state.search.filters,
                        searchTerms: action.payload.searchTerms,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@GROUPS/SET_SEARCH_GROUPS_ONLY_ACTIVE':
            if (state.search.filters.onlyActive === action.payload.onlyActive) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state.search.filters,
                        onlyActive: action.payload.onlyActive,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@GROUPS/SET_SEARCH_GROUPS_ONLY_RESEARCH_GROUPS':
            if (state.search.filters.onlyResearchGroups === action.payload.onlyResearchGroups) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state.search.filters,
                        onlyResearchGroups: action.payload.onlyResearchGroups,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@GROUPS/SEARCH_GROUPS_CHANGE_PAGE':
            if (state.search.filters.skip === action.payload.skip && state.search.filters.take === action.payload.take) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state.search.filters,
                        skip: action.payload.skip,
                        take: action.payload.take,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@GROUPS/RESET_SEARCH_GROUPS':
            const isAlreadyDefaultFilter = Object.entries(state.search.filters).every(([filterKey, filterValue]) => {
                switch (filterKey) {
                    case 'skip':
                        return filterValue === 0;
                    case 'take':
                        return filterValue === DEFAULT_SEARCH_GROUPS_FILTERS.take;
                    case 'onlyActive':
                        return filterValue === DEFAULT_SEARCH_GROUPS_FILTERS.onlyActive;
                    default:
                        return !filterValue;
                }
            });
            if (isAlreadyDefaultFilter) {
                return state;
            }

            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...DEFAULT_SEARCH_GROUPS_FILTERS,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@GROUPS/SEARCH_GROUPS':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: true,
                },
            };

        case '@GROUPS/SEARCH_GROUPS_SUCCESS':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: false,
                    isFetching: false,
                    data: action.payload.groupIds,
                    total: action.payload.total,
                },
                groups: createFetchGroupsState(
                    state,
                    action.payload.groupIds,
                    {
                        isFetching: false,
                        didInvalidate: false,
                    }),
            };

        case '@GROUPS/SEARCH_GROUPS_FAILURE':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@GROUPS/CREATE_GROUP':
            return {
                ...state,
                creation: {
                    ...state.creation,
                    [action.payload.name]: true,
                },
            };

        case '@GROUPS/CREATE_GROUP_SUCCESS':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: false,
                },
                creation: {
                    ...state.creation,
                    [action.payload.name]: false,
                },
            };

        case '@GROUPS/CREATE_GROUP_FAILURE':
            return {
                ...state,
                creation: {
                    ...state.creation,
                    [action.payload.name]: false,
                },
            };

        case '@GROUPS/FETCH_USERS_BY_GROUP_ID':
            return {
                ...state,
                users: {
                    ...state.users,
                    [action.payload.groupId]: {
                        ...state.users[action.payload.groupId],
                        isFetching: true,
                        didInvalidate: true,
                    },
                },
            };

        case '@GROUPS/FETCH_USERS_BY_GROUP_ID_SUCCESS':
            return {
                ...state,
                users: {
                    ...state.users,
                    [action.payload.groupId]: {
                        ...state.users[action.payload.groupId],
                        isFetching: false,
                        didInvalidate: false,
                        data: action.payload.userIds,
                    },
                },
            };

        case '@GROUPS/FETCH_USERS_BY_GROUP_ID_FAILURE':
            return {
                ...state,
                users: {
                    ...state.users,
                    [action.payload.groupId]: {
                        ...state.users[action.payload.groupId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@GROUPS/FETCH_GROUPS':
            return {
                ...state,
                groups: createFetchGroupsState(
                    state,
                    action.payload.groupIds,
                    {
                        isFetching: true,
                        didInvalidate: true,
                    }),
            };

        case '@GROUPS/FETCH_GROUPS_SUCCESS':
            return {
                ...state,
                groups: createFetchGroupsState(
                    state,
                    action.payload.groupIds,
                    {
                        isFetching: false,
                        didInvalidate: false,
                    }),
            };

        case '@GROUPS/FETCH_GROUPS_FAILURE':
            return {
                ...state,
                groups: createFetchGroupsState(
                    state,
                    action.payload.groupIds,
                    {
                        isFetching: false,
                        didInvalidate: true,
                    }),
            };

        case '@GROUPS/UPDATE_GROUP':
            return {
                ...state,
                update: {
                    ...state.update,
                    [action.payload.groupId]: true,
                },
            };

        case '@GROUPS/UPDATE_GROUP_SUCCESS':
            return {
                ...state,
                update: {
                    ...state.update,
                    [action.payload.groupId]: false,
                },
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: false,
                },
                groups: {
                    ...state.groups,
                    [action.payload.groupId]: {
                        ...state.groups[action.payload.groupId],
                        didInvalidate: true,
                        isFetching: false,
                    },
                },
            };


        case '@GROUPS/UPDATE_GROUP_FAILURE':
            return {
                ...state,
                update: {
                    ...state.update,
                    [action.payload.groupId]: false,
                },
            };

        case '@GROUPS/DEACTIVATE_GROUP':
            return {
                ...state,
                deactivate: {
                    ...state.deactivate,
                    [action.payload.groupId]: true,
                },
            };

        case '@GROUPS/DEACTIVATE_GROUP_SUCCESS':
            return {
                ...state,
                deactivate: {
                    ...state.deactivate,
                    [action.payload.groupId]: false,
                },
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: false,
                },
            };


        case '@GROUPS/DEACTIVATE_GROUP_FAILURE':
            return {
                ...state,
                deactivate: {
                    ...state.deactivate,
                    [action.payload.groupId]: false,
                },
            };

        case '@GROUPS/REACTIVATE_GROUP':
            return {
                ...state,
                reactivate: {
                    ...state.reactivate,
                    [action.payload.groupId]: true,
                },
            };

        case '@GROUPS/REACTIVATE_GROUP_SUCCESS':
            return {
                ...state,
                reactivate: {
                    ...state.reactivate,
                    [action.payload.groupId]: false,
                },
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@GROUPS/REACTIVATE_GROUP_FAILURE':
            return {
                ...state,
                reactivate: {
                    ...state.reactivate,
                    [action.payload.groupId]: false,
                },
            };

        case '@GROUPS/DEACTIVATE_USER_GROUP':
            return {
                ...state,
                deactivateUser: {
                    ...state.deactivate,
                    [getUserGroupKey(action.payload)]: true,
                },
            };

        case '@GROUPS/DEACTIVATE_USER_GROUP_SUCCESS':
            return {
                ...state,
                deactivateUser: {
                    ...state.deactivate,
                    [getUserGroupKey(action.payload)]: false,
                },
                users: {
                    ...state.users,
                    [action.payload.groupId]: {
                        ...state.users[action.payload.groupId],
                        data: state.users[action.payload.groupId]?.data?.filter(icId => icId != action.payload.icId),
                        didInvalidate: true,
                        isFetching: false,
                    },
                },
            };

        case '@GROUPS/DEACTIVATE_USER_GROUP_FAILURE':
            return {
                ...state,
                deactivateUser: {
                    ...state.deactivate,
                    [getUserGroupKey(action.payload)]: false,
                },
            };

        case '@GROUPS/ADD_GROUPS_TO_ANALYST':
            return {
                ...state,
                addGroupsToAnalyst: {
                    ...state.addGroupsToAnalyst,
                    [getAnalystGroupsKey(action.payload)]: true,
                },
            };

        case '@GROUPS/ADD_GROUPS_TO_ANALYST_SUCCESS':
            return {
                ...state,
                addGroupsToAnalyst: {
                    ...state.addGroupsToAnalyst,
                    [getAnalystGroupsKey(action.payload)]: false,
                },
                users: invalidateGroupsUsers(state, action.payload.groupIds),
            };

        case '@GROUPS/ADD_GROUPS_TO_ANALYST_FAILURE':
            return {
                ...state,
                addGroupsToAnalyst: {
                    ...state.addGroupsToAnalyst,
                    [getAnalystGroupsKey(action.payload)]: false,
                },
            };

        default:
            return state;
    }
};

const createFetchGroupsState = (state: GroupsState, groupIds: number[], apiRequest: ApiRequest) => {
    let stateGroups = {
        ...state.groups,
    };
    for (const groupId of groupIds) {
        stateGroups = {
            ...stateGroups,
            [groupId]: {
                ...state.groups[groupId],
                ...apiRequest,
            },
        };
    }
    return stateGroups;
};

export const getUserGroupKey = (payload: { groupId: number, icId: string }) => {
    return payload.icId + '_' + payload.groupId;
};

export const getAnalystGroupsKey = (payload: { groupIds: number[], icId: string }) => {
    return  payload.icId + '_' + payload.groupIds.toString();
};

export const invalidateGroupsUsers = (state: GroupsState, groupIds: number[]) => {
    let stateGroups = {
        ...state.users,
    };

    for (const groupId of groupIds) {
        stateGroups = {
            ...stateGroups,
            [groupId]: {
                ...stateGroups[groupId],
                didInvalidate: true,
            },
        };
    }
    return stateGroups;
};
