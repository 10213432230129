import classNames from 'classnames';
import { useAppContext } from 'components/common/AppProvider';
import React, { useEffect } from 'react';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { matchPath, NavLink, useLocation } from 'react-router-dom';
import { RoutePaths } from 'routes/RoutePaths';
import { track, TrackedPageType } from 'services/Logger';
import { hasRight, Permissions } from 'services/RightsService';
import { getAppContext } from 'store/AppContext/AppContextThunk';
import { InsightBackofficeSelectors } from 'store/Normalizr/Selectors';

interface HeaderMenuProps {
    className?: string
}

export const HeaderMenu: React.FC<HeaderMenuProps> = ({ className }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { dispatch, state: { appContext: { loggedUserId }, entities } } = useAppContext();
    const loggedUser = InsightBackofficeSelectors.getLoggedUser(loggedUserId, entities.insightBackoffice);

    const handleClick = (pageName: string) => {
        track('ClickOnMenu', 'click on menu', { page: TrackedPageType.Menu, pageName });
    };

    useEffect(() => {
        dispatch(getAppContext()).catch(() => void 0);
    });

    const isAdministrator = hasRight(loggedUser, Permissions.Administrator);

    return <ul className={className} data-bs-popper="static">

        <li className="navbar-item">
            <NavLink
                end
                to={RoutePaths.ResearchCompanies.AllCompanies.url()}
                className={classNames('navbar-link', {
                    active:
                        !!matchPath({
                            path: RoutePaths.ResearchCompanies.AllCompanies.route,
                            end: true,
                        }, pathname)
                        || !!matchPath({
                            path: RoutePaths.ResearchCompanies.AllCompanies.Edit.route,
                            end: false,
                        }, pathname),
                })}
                onMouseUp={() => handleClick('All companies')}
            >
                {RoutePaths.ResearchCompanies.AllCompanies.title(t)}
            </NavLink>
        </li> 
        {isAdministrator ? <>
            <li className="navbar-item dropdown">
                <UncontrolledDropdown>
                    <DropdownToggle
                        caret={true}
                        color=""
                        className={classNames('navbar-link', {
                            active: !!matchPath({
                                    path: RoutePaths.AuthorsAndGroups.Authors.route,
                                    end: false,
                                }, pathname)
                                || !!matchPath({
                                    path: RoutePaths.AuthorsAndGroups.Groups.route,
                                    end: false,
                                }, pathname),
                        })}
                        id="AuthorsAndGroupsDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        {RoutePaths.AuthorsAndGroups.title(t)}
                    </DropdownToggle>
                    <DropdownMenu tag="ul" aria-labelledby="AuthorsAndGroupsDropdown">
                        <li>
                            <NavLink
                                className="dropdown-item"
                                to={RoutePaths.AuthorsAndGroups.Authors.url()}
                                onMouseUp={() => handleClick('Authors')}
                            >
                                {RoutePaths.AuthorsAndGroups.Authors.title(t)}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="dropdown-item"
                                to={RoutePaths.AuthorsAndGroups.Groups.url()}
                                onMouseUp={() => handleClick('Groups')}
                            >
                                {RoutePaths.AuthorsAndGroups.Groups.title(t)}
                            </NavLink>
                        </li>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </li>

            <li className="navbar-item dropdown">
                <UncontrolledDropdown>
                    <DropdownToggle
                        caret={true}
                        color=""
                        className={classNames('navbar-link', {
                            active: !!matchPath({
                                    path: RoutePaths.ProductsAndSubscriptions.Products.route,
                                    end: false,
                                }, pathname)
                                || !!matchPath({
                                    path: RoutePaths.ProductsAndSubscriptions.Senders.route,
                                    end: false,
                                }, pathname)
                                || !!matchPath({
                                    path: RoutePaths.ProductsAndSubscriptions.Keywords.route,
                                    end: false,
                                }, pathname),
                        })}
                        id="ProductsAndSubscriptionsDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        {RoutePaths.ProductsAndSubscriptions.title(t)}
                    </DropdownToggle>
                    <DropdownMenu tag="ul" aria-labelledby="ProductsAndSubscriptionsDropdown">
                        <li>
                            <NavLink
                                className="dropdown-item"
                                to={RoutePaths.ProductsAndSubscriptions.Products.url()}
                                onMouseUp={() => handleClick('Products')}
                            >
                                {RoutePaths.ProductsAndSubscriptions.Products.title(t)}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="dropdown-item"
                                to={RoutePaths.ProductsAndSubscriptions.Senders.url()}
                                onMouseUp={() => handleClick('Senders')}
                            >
                                {RoutePaths.ProductsAndSubscriptions.Senders.title(t)}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="dropdown-item"
                                to={RoutePaths.ProductsAndSubscriptions.Keywords.url()}
                                onMouseUp={() => handleClick('Keywords')}
                            >
                                {RoutePaths.ProductsAndSubscriptions.Keywords.title(t)}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="dropdown-item"
                                to={RoutePaths.ProductsAndSubscriptions.ResearchCategories.url()}
                                onMouseUp={() => handleClick('Research Categories')}
                            >
                                {RoutePaths.ProductsAndSubscriptions.ResearchCategories.title(t)}
                            </NavLink>
                        </li>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </li>
        </> : null}
    </ul>;
};
