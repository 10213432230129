import { SearchSendersRequest } from 'services/ApiService/InsightBackoffice/InsightBackofficeApiClient';
import { ApiRequest, Reducer } from 'store';

export const DEFAULT_SEARCH_SENDERS_FILTERS: SearchSendersRequest = {
    skip: 0,
    take: 15,
    onlyActive: true,
};

export const initialState = {
    search: {
        filters: {
            ...DEFAULT_SEARCH_SENDERS_FILTERS,
        },
        total: 0,
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]> & {
        filters: SearchSendersRequest,
        total: number,
    },
    searchForAdd: {
        filters: {
            ...DEFAULT_SEARCH_SENDERS_FILTERS,
        },
        total: 0,
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]> & {
        filters: SearchSendersRequest,
        total: number,
    },
    creation: {} as { readonly [name: string]: boolean },
    update: {} as { readonly [senderId: number]: boolean },
    deactivate: {} as { readonly [senderId: number]: boolean },
    reactivate: {} as { readonly [senderId: number]: boolean },
    senders: {} as { readonly [id: number]: ApiRequest },
} as const;

export type SendersState = typeof initialState;

export const SendersReducer: Reducer<SendersState> = (state = initialState, action) => {
    switch (action.type) {
        case '@SENDERS/SET_SEARCH_SENDERS_SEARCH_TERMS':
            if (state.search.filters.searchTerms?.toLowerCase() === action.payload.searchTerms?.toLowerCase()) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state.search.filters,
                        searchTerms: action.payload.searchTerms,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@SENDERS/SET_SEARCH_SENDERS_ONLY_ACTIVE':
            if (state.search.filters.onlyActive === action.payload.onlyActive) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state.search.filters,
                        onlyActive: action.payload.onlyActive,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@SENDERS/SEARCH_SENDERS_CHANGE_PAGE':
            if (state.search.filters.skip === action.payload.skip && state.search.filters.take === action.payload.take) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state.search.filters,
                        skip: action.payload.skip,
                        take: action.payload.take,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@SENDERS/RESET_SEARCH_SENDERS':
            const isAlreadyDefaultFilter = Object.entries(state.search.filters).every(([filterKey, filterValue]) => {
                switch (filterKey) {
                    case 'skip':
                        return filterValue === 0;
                    case 'take':
                        return filterValue === DEFAULT_SEARCH_SENDERS_FILTERS.take;
                    case 'onlyActive':
                        return filterValue === DEFAULT_SEARCH_SENDERS_FILTERS.onlyActive;
                    default:
                        return !filterValue;
                }
            });
            if (isAlreadyDefaultFilter) {
                return state;
            }

            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...DEFAULT_SEARCH_SENDERS_FILTERS,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@SENDERS/SEARCH_SENDERS':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: true,
                },
            };

        case '@SENDERS/SEARCH_SENDERS_SUCCESS':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: false,
                    isFetching: false,
                    data: action.payload.senderIds,
                    total: action.payload.total,
                },
                senders: createFetchSendersState(
                    state,
                    action.payload.senderIds,
                    {
                        didInvalidate: false,
                        isFetching: false,
                    }),
            };

        case '@SENDERS/SEARCH_SENDERS_FAILURE':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@SENDERS/SEARCH_SENDERS_FOR_ADD':
            return {
                ...state,
                searchForAdd: {
                    ...state.searchForAdd,
                    didInvalidate: true,
                    isFetching: true,
                },
            };

        case '@SENDERS/SEARCH_SENDERS_FOR_ADD_SUCCESS':
            return {
                ...state,
                searchForAdd: {
                    ...state.searchForAdd,
                    didInvalidate: false,
                    isFetching: false,
                    data: action.payload.senderIds,
                    total: action.payload.total,
                },
                senders: createFetchSendersState(
                    state,
                    action.payload.senderIds,
                    {
                        didInvalidate: false,
                        isFetching: false,
                    }),
            };

        case '@SENDERS/SEARCH_SENDERS_FOR_ADD_FAILURE':
            return {
                ...state,
                searchForAdd: {
                    ...state.searchForAdd,
                    didInvalidate: true,
                    isFetching: false,
                },
            };


        case '@SENDERS/SET_SEARCH_SENDERS_FOR_ADD_SEARCH_TERMS':
            if (state.searchForAdd.filters.searchTerms?.toLowerCase() === action.payload.searchTerms?.toLowerCase()) {
                return state;
            }
            return {
                ...state,
                searchForAdd: {
                    ...state.searchForAdd,
                    filters: {
                        ...state.searchForAdd.filters,
                        searchTerms: action.payload.searchTerms,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@SENDERS/SEARCH_SENDERS_FOR_ADD_CHANGE_PAGE':
            if (state.searchForAdd.filters.skip === action.payload.skip && state.searchForAdd.filters.take === action.payload.take) {
                return state;
            }
            return {
                ...state,
                searchForAdd: {
                    ...state.searchForAdd,
                    filters: {
                        ...state.searchForAdd.filters,
                        skip: action.payload.skip,
                        take: action.payload.take,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@SENDERS/CREATE_SENDER':
            return {
                ...state,
                creation: {
                    ...state.creation,
                    [action.payload.name]: true,
                },
            };

        case '@SENDERS/CREATE_SENDER_SUCCESS':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: false,
                },
                creation: {
                    ...state.creation,
                    [action.payload.name]: false,
                },
            };

        case '@SENDERS/CREATE_SENDER_FAILURE':
            return {
                ...state,
                creation: {
                    ...state.creation,
                    [action.payload.name]: false,
                },
            };

        case '@SENDERS/UPDATE_SENDER':
            return {
                ...state,
                update: {
                    ...state.update,
                    [action.payload.senderId]: true,
                },
            };

        case '@SENDERS/UPDATE_SENDER_SUCCESS':
            return {
                ...state,
                update: {
                    ...state.update,
                    [action.payload.senderId]: false,
                },
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: false,
                },
                senders: createFetchSendersState(
                    state,
                    [action.payload.senderId],
                    {
                        didInvalidate: true,
                        isFetching: false,
                    }),
            };

        case '@SENDERS/UPDATE_SENDER_FAILURE':
            return {
                ...state,
                update: {
                    ...state.update,
                    [action.payload.senderId]: false,
                },
            };

        case '@SENDERS/DEACTIVATE_SENDER':
            return {
                ...state,
                deactivate: {
                    ...state.deactivate,
                    [action.payload.senderId]: true,
                },
            };

        case '@SENDERS/DEACTIVATE_SENDER_SUCCESS':
            return {
                ...state,
                deactivate: {
                    ...state.deactivate,
                    [action.payload.senderId]: false,
                },
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: false,
                },
                senders: createFetchSendersState(
                    state,
                    [action.payload.senderId],
                    {
                        didInvalidate: true,
                        isFetching: false,
                    }),
            };


        case '@SENDERS/DEACTIVATE_SENDER_FAILURE':
            return {
                ...state,
                deactivate: {
                    ...state.deactivate,
                    [action.payload.senderId]: false,
                },
            };

        case '@SENDERS/REACTIVATE_SENDER':
            return {
                ...state,
                reactivate: {
                    ...state.reactivate,
                    [action.payload.senderId]: true,
                },
            };

        case '@SENDERS/REACTIVATE_SENDER_SUCCESS':
            return {
                ...state,
                reactivate: {
                    ...state.reactivate,
                    [action.payload.senderId]: false,
                },
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: false,
                },
                senders: createFetchSendersState(
                    state,
                    [action.payload.senderId],
                    {
                        didInvalidate: true,
                        isFetching: false,
                    }),
            };

        case '@SENDERS/REACTIVATE_SENDER_FAILURE':
            return {
                ...state,
                reactivate: {
                    ...state.reactivate,
                    [action.payload.senderId]: false,
                },
            };

        case '@SENDERS/FETCH_SENDER_BY_IDS':
            return {
                ...state,
                senders: createFetchSendersState(
                    state,
                    action.payload.senderIds,
                    {
                        isFetching: true,
                        didInvalidate: true,
                    }),
            };

        case '@SENDERS/FETCH_SENDER_BY_IDS_SUCCESS':
            return {
                ...state,
                senders: createFetchSendersState(
                    state,
                    action.payload.senderIds,
                    {
                        isFetching: false,
                        didInvalidate: false,
                    }),
            };

        case '@SENDERS/FETCH_SENDER_BY_IDS_FAILURE':
            return {
                ...state,
                senders: createFetchSendersState(
                    state,
                    action.payload.senderIds,
                    {
                        isFetching: false,
                        didInvalidate: true,
                    }),
            };

        default:
            return state;
    }
};

const createFetchSendersState = (state: SendersState, senderIds: number[], apiRequest: ApiRequest) => {
    let stateSenders = {
        ...state.senders,
    };

    for (const senderId of senderIds) {
        stateSenders = {
            ...stateSenders,
            [senderId]: {
                ...state.senders[senderId],
                ...apiRequest,
            },
        };
    }
    return stateSenders;
};
