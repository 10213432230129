import {
    ProductDistributionConfiguration,
    ProductDistributionConfigurationRequest,
    ProductFilter,
    ProductGeneralProperties,
    ProductInsightConfiguration,
    ProductInsightConfigurationRequest,
    ProductPublicationConfig,
    ProductWorkflow,
    SubscribersCountForProducts,
    UpdateProductDistributionConfigurationResponse,
    UpdateProductDomainPublicationConfigurationRequest,
    UpdateProductPublicationConfigRequest,
    UpdateProductWorkflowRequestDto,
} from 'services/ApiService/InsightBackoffice/InsightBackofficeApiClient';
import { ApiRequest, Reducer } from 'store';

export const DEFAULT_SEARCH_PRODUCT_FILTERS: ProductFilter = {
    skip: 0,
    take: 30,
    includeInactive: false,
};

interface ICreateProductInformation {
    productGeneralProperties?: ProductGeneralProperties,
    productPublicationConfig?: UpdateProductPublicationConfigRequest,
    productWorkflow?: UpdateProductWorkflowRequestDto,
    productDomainPublicationConfig?: UpdateProductDomainPublicationConfigurationRequest,
    productInsightConfig?: ProductInsightConfigurationRequest,
    productDistributionConfig?: ProductDistributionConfigurationRequest,
    copyFromProductId?: number,
}

const CREATE_PRODUCT_INFORMATION_DEFAULT_REQUEST = {
} as ICreateProductInformation;

export const initialState = {
    searchProducts: {
        filters: {
            ...DEFAULT_SEARCH_PRODUCT_FILTERS,
        },
        total: 0,
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]> & {
        filters: ProductFilter,
        total: number,
    },
    searchForAdd: {
        filters: {
            ...DEFAULT_SEARCH_PRODUCT_FILTERS,
        },
        total: 0,
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]> & {
        filters: ProductFilter,
        total: number,
    },
    products: {} as { readonly [id: number]: ApiRequest },
    creatingProduct: {} as ApiRequest<UpdateProductDistributionConfigurationResponse>,
    createProductInformation: {
        ...CREATE_PRODUCT_INFORMATION_DEFAULT_REQUEST,
    } as ICreateProductInformation,
    productDistributionConfigs: {} as {
        readonly [productId: number]: ApiRequest<ProductDistributionConfiguration> & { getPublicationExportConfigOnly: boolean | undefined }
    },
    productWorkflows: {} as {
        readonly [productId: number]: ApiRequest<ProductWorkflow>
    },
    publicationConfigurations: {} as {
        readonly [productId: number]: ApiRequest<ProductPublicationConfig>
    },
    insightConfigurations: {} as {
        readonly [productId: number]: ApiRequest<ProductInsightConfiguration>
    },
    domainPublicationConfigurations: {} as {
        readonly [productId: number]: ApiRequest<UpdateProductDomainPublicationConfigurationRequest>
    },
    deactivate: {} as { readonly [productId: number]: boolean },
    reactivate: {} as { readonly [productId: number]: boolean },
    subscribersCountForProducts: { isFetching: false, didInvalidate: false } as ApiRequest<SubscribersCountForProducts[]> | undefined,

} as const;

export type ProductsState = typeof initialState;

export const ProductsReducer: Reducer<ProductsState> = (state = initialState, action) => {
    let isAlreadyDefaultFilter: boolean;
    switch (action.type) {
        case '@PRODUCTS/SET_SEARCH_PRODUCTS_SEARCH_TERMS':
            if (state.searchProducts.filters.searchTerms?.toLowerCase() === action.payload.searchTerms?.toLowerCase()) {
                return state;
            }
            return {
                ...state,
                searchProducts: {
                    ...state.searchProducts,
                    filters: {
                        ...state.searchProducts.filters,
                        searchTerms: action.payload.searchTerms,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@PRODUCTS/SET_SEARCH_PRODUCTS_ONLY_ACTIVE':
            if (state.searchProducts.filters.includeInactive === !action.payload.onlyActive) {
                return state;
            }
            return {
                ...state,
                searchProducts: {
                    ...state.searchProducts,
                    filters: {
                        ...state.searchProducts.filters,
                        includeInactive: !action.payload.onlyActive,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@PRODUCTS/SEARCH_PRODUCTS_CHANGE_PAGE':
            if (state.searchProducts.filters.skip === action.payload.skip && state.searchProducts.filters.take === action.payload.take) {
                return state;
            }
            return {
                ...state,
                searchProducts: {
                    ...state.searchProducts,
                    filters: {
                        ...state.searchProducts.filters,
                        skip: action.payload.skip,
                        take: action.payload.take,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@PRODUCTS/RESET_SEARCH_PRODUCTS':
            isAlreadyDefaultFilter = Object.entries(state.searchProducts.filters).every(([filterKey, filterValue]) => {
                switch (filterKey) {
                    case 'skip':
                        return filterValue === 0;
                    case 'take':
                        return filterValue === DEFAULT_SEARCH_PRODUCT_FILTERS.take;
                    case 'onlyActive':
                        return filterValue === DEFAULT_SEARCH_PRODUCT_FILTERS.includeInactive;
                    default:
                        return !filterValue;
                }
            });
            if (isAlreadyDefaultFilter) {
                return state;
            }

            return {
                ...state,
                searchProducts: {
                    ...state.searchProducts,
                    filters: {
                        ...DEFAULT_SEARCH_PRODUCT_FILTERS,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@PRODUCTS/SEARCH_PRODUCTS':
            return {
                ...state,
                searchProducts: {
                    ...state.searchProducts,
                    didInvalidate: true,
                    isFetching: true,
                },
            };

        case '@PRODUCTS/SEARCH_PRODUCTS_SUCCESS':
            return {
                ...state,
                searchProducts: {
                    ...state.searchProducts,
                    didInvalidate: false,
                    isFetching: false,
                    data: action.payload.productIds,
                    total: action.payload.total,
                },
                products: createFetchProductsState(
                    state,
                    action.payload.productIds,
                    {
                        isFetching: false,
                        didInvalidate: false,
                    }),
            };

        case '@PRODUCTS/SEARCH_PRODUCTS_FAILURE':
            return {
                ...state,
                searchProducts: {
                    ...state.searchProducts,
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@PRODUCTS/SEARCH_PRODUCTS_FOR_ADD':
            return {
                ...state,
                searchForAdd: {
                    ...state.searchForAdd,
                    didInvalidate: true,
                    isFetching: true,
                },
            };

        case '@PRODUCTS/SEARCH_PRODUCTS_FOR_ADD_SUCCESS':
            return {
                ...state,
                searchForAdd: {
                    ...state.searchForAdd,
                    didInvalidate: false,
                    isFetching: false,
                    data: action.payload.productIds,
                    total: action.payload.total,
                },
            };

        case '@PRODUCTS/SEARCH_PRODUCTS_FOR_ADD_FAILURE':
            return {
                ...state,
                searchForAdd: {
                    ...state.searchForAdd,
                    didInvalidate: true,
                    isFetching: false,
                },
            };


        case '@PRODUCTS/SET_SEARCH_PRODUCTS_FOR_ADD_SEARCH_TERMS':
            if (state.searchForAdd.filters.searchTerms?.toLowerCase() === action.payload.searchTerms?.toLowerCase()) {
                return state;
            }
            return {
                ...state,
                searchForAdd: {
                    ...state.searchForAdd,
                    filters: {
                        ...state.searchForAdd.filters,
                        searchTerms: action.payload.searchTerms,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@PRODUCTS/SEARCH_PRODUCTS_FOR_ADD_CHANGE_PAGE':
            if (state.searchForAdd.filters.skip === action.payload.skip && state.searchForAdd.filters.take === action.payload.take) {
                return state;
            }
            return {
                ...state,
                searchForAdd: {
                    ...state.searchForAdd,
                    filters: {
                        ...state.searchForAdd.filters,
                        skip: action.payload.skip,
                        take: action.payload.take,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@PRODUCTS/UPDATE_CREATE_PRODUCT_INFORMATION_GENERAL_PROPERTIES':
            return {
                ...state,
                createProductInformation: {
                    ...state.createProductInformation,
                    productGeneralProperties: action.payload.productGeneralProperties,
                },
            };

        case '@PRODUCTS/UPDATE_PRODUCT_DOMAIN_PUBLICATION_CONFIGURATION':
            return {
                ...state,
                createProductInformation: {
                    ...state.createProductInformation,
                    productDomainPublicationConfig: action.payload.configuration,
                },
            };

        case '@PRODUCTS/UPDATE_CREATE_PRODUCT_INFORMATION_PUBLICATION':
            return {
                ...state,
                createProductInformation: {
                    ...state.createProductInformation,
                    productPublicationConfig: action.payload.productPublicationConfig,
                },
            };

        case '@PRODUCTS/UPDATE_PRODUCT_INSIGHT_CONFIGURATION':
            return {
                ...state,
                createProductInformation: {
                    ...state.createProductInformation,
                    productInsightConfig: action.payload.productInsightConfiguration,
                },
            };

        case '@PRODUCTS/UPDATE_PRODUCT_DISTRIBUTION_CONFIGURATION':
            return {
                ...state,
                createProductInformation: {
                    ...state.createProductInformation,
                    productDistributionConfig: action.payload.productDistributionConfiguration,
                },
            };

        case '@PRODUCTS/UPDATE_CREATE_PRODUCT_INFORMATION_WORKFLOWS':
            return {
                ...state,
                createProductInformation: {
                    ...state.createProductInformation,
                    productWorkflow: action.payload.productWorkflow,
                },
            };

        case '@PRODUCTS/UPDATE_CREATE_PRODUCT_INFORMATION_COPY':
            return {
                ...state,
                createProductInformation: {
                    ...state.createProductInformation,
                    copyFromProductId: action.payload.copyFromProductId,
                },
            };

        case '@PRODUCTS/UPDATE_CREATE_PRODUCT_INFORMATION_CLEAR':
            return {
                ...state,
                createProductInformation: {},
            };

        case '@PRODUCTS/CREATE_PRODUCT':
            return {
                ...state,
                creatingProduct: {
                    didInvalidate: false,
                    isFetching: true,
                },
            };

        case '@PRODUCTS/CREATE_PRODUCT_SUCCESS':
            const newState = {
                ...state,
                creatingProduct: {
                    didInvalidate: false,
                    isFetching: false,
                    data: action.payload.distributionConfigurationResponse,
                },
                createProductInformation: {
                    ...state.createProductInformation,
                },
                searchProducts: {
                    ...state.searchProducts,
                    didInvalidate: true,
                },
            };
            if (action.payload.productId) {
                newState.products = {
                    ...newState.products,
                    [action.payload.productId]: {
                        ...state.products[action.payload.productId],
                        didInvalidate: true,
                    },
                };
                newState.productDistributionConfigs = {
                    ...newState.productDistributionConfigs,
                    [action.payload.productId]: {
                        ...state.productDistributionConfigs[action.payload.productId],
                        didInvalidate: true,
                    },
                };
                newState.productWorkflows = {
                    ...newState.productWorkflows,
                    [action.payload.productId]: {
                        ...state.productWorkflows[action.payload.productId],
                        didInvalidate: true,
                    },
                };
                newState.publicationConfigurations = {
                    ...newState.publicationConfigurations,
                    [action.payload.productId]: {
                        ...state.publicationConfigurations[action.payload.productId],
                        didInvalidate: true,
                    },
                };
                newState.insightConfigurations = {
                    ...newState.insightConfigurations,
                    [action.payload.productId]: {
                        ...state.insightConfigurations[action.payload.productId],
                        didInvalidate: true,
                    },
                };
                newState.domainPublicationConfigurations = {
                    ...newState.domainPublicationConfigurations,
                    [action.payload.productId]: {
                        ...state.domainPublicationConfigurations[action.payload.productId],
                        didInvalidate: true,
                    },
                };
            }
            return newState;

        case '@PRODUCTS/CREATE_PRODUCT_FAILURE':
            return {
                ...state,
                creatingProduct: {
                    didInvalidate: false,
                    isFetching: false,
                },
            };

        case '@PRODUCTS/FETCH_PRODUCTS':
            return {
                ...state,
                products: createFetchProductsState(
                    state,
                    action.payload.ids,
                    {
                        isFetching: true,
                        didInvalidate: true,
                    }),
            };

        case '@PRODUCTS/FETCH_PRODUCTS_SUCCESS':
            return {
                ...state,
                products: createFetchProductsState(
                    state,
                    action.payload.ids,
                    {
                        isFetching: false,
                        didInvalidate: false,
                    }),
            };

        case '@PRODUCTS/FETCH_PRODUCTS_FAILURE':
            return {
                ...state,
                products: createFetchProductsState(
                    state,
                    action.payload.ids,
                    {
                        isFetching: false,
                        didInvalidate: true,
                    }),
            };

        case '@PRODUCTS/FETCH_DISTRIBUTION_CONFIGURATION':
            return {
                ...state,
                productDistributionConfigs: {
                    ...state.productDistributionConfigs,
                    [action.payload.productId]: {
                        ...state.productDistributionConfigs[action.payload.productId],
                        getPublicationExportConfigOnly: action.payload.getPublicationExportConfigOnly,
                        isFetching: true,
                        didInvalidate: true,
                    },
                },
            };

        case '@PRODUCTS/FETCH_DISTRIBUTION_CONFIGURATION_SUCCESS':
            return {
                ...state,
                productDistributionConfigs: {
                    ...state.productDistributionConfigs,
                    [action.payload.productId]: {
                        ...state.productDistributionConfigs[action.payload.productId],
                        isFetching: false,
                        didInvalidate: false,
                        data: action.payload.distributionConfiguration,
                    },
                },
            };

        case
            '@PRODUCTS/FETCH_DISTRIBUTION_CONFIGURATION_FAILURE'
            :
            return {
                ...state,
                productDistributionConfigs: {
                    ...state.productDistributionConfigs,
                    [action.payload.productId]: {
                        ...state.productDistributionConfigs[action.payload.productId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@PRODUCTS/FETCH_PRODUCT_WORKFLOWS':
            return {
                ...state,
                productWorkflows: {
                    ...state.productWorkflows,
                    [action.payload.productId]: {
                        ...state.productWorkflows[action.payload.productId],
                        isFetching: true,
                        didInvalidate: true,
                    },
                },
            };

        case '@PRODUCTS/FETCH_PRODUCT_WORKFLOWS_SUCCESS':
            return {
                ...state,
                productWorkflows: {
                    ...state.productWorkflows,
                    [action.payload.productId]: {
                        ...state.productWorkflows[action.payload.productId],
                        isFetching: false,
                        didInvalidate: false,
                        data: action.payload.productWorkflow,
                    },
                },
            };

        case
            '@PRODUCTS/FETCH_PRODUCT_WORKFLOWS_FAILURE'
            :
            return {
                ...state,
                productWorkflows: {
                    ...state.productWorkflows,
                    [action.payload.productId]: {
                        ...state.productWorkflows[action.payload.productId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@PRODUCTS/FETCH_PUBLICATION_CONFIGURATION':
            return {
                ...state,
                publicationConfigurations: {
                    ...state.publicationConfigurations,
                    [action.payload.productId]: {
                        ...state.publicationConfigurations[action.payload.productId],
                        isFetching: true,
                        didInvalidate: true,
                    },
                },
            };

        case '@PRODUCTS/FETCH_PUBLICATION_CONFIGURATION_SUCCESS':
            return {
                ...state,
                publicationConfigurations: {
                    ...state.publicationConfigurations,
                    [action.payload.productId]: {
                        ...state.publicationConfigurations[action.payload.productId],
                        isFetching: false,
                        didInvalidate: false,
                        data: action.payload.productPublicationConfig,
                    },
                },
            };

        case
            '@PRODUCTS/FETCH_PUBLICATION_CONFIGURATION_FAILURE'
            :
            return {
                ...state,
                publicationConfigurations: {
                    ...state.publicationConfigurations,
                    [action.payload.productId]: {
                        ...state.publicationConfigurations[action.payload.productId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@PRODUCTS/FETCH_INSIGHT_CONFIGURATION':
            return {
                ...state,
                insightConfigurations: {
                    ...state.insightConfigurations,
                    [action.payload.productId]: {
                        ...state.insightConfigurations[action.payload.productId],
                        isFetching: true,
                        didInvalidate: true,
                    },
                },
            };

        case '@PRODUCTS/FETCH_INSIGHT_CONFIGURATION_SUCCESS':
            return {
                ...state,
                insightConfigurations: {
                    ...state.insightConfigurations,
                    [action.payload.productId]: {
                        ...state.insightConfigurations[action.payload.productId],
                        isFetching: false,
                        didInvalidate: false,
                        data: action.payload.productInsightConfiguration,
                    },
                },
            };

        case '@PRODUCTS/FETCH_INSIGHT_CONFIGURATION_FAILURE':
            return {
                ...state,
                insightConfigurations: {
                    ...state.insightConfigurations,
                    [action.payload.productId]: {
                        ...state.insightConfigurations[action.payload.productId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@PRODUCTS/FETCH_DOMAIN_PUBLICATION_CONFIGURATION':
            return {
                ...state,
                domainPublicationConfigurations: {
                    ...state.domainPublicationConfigurations,
                    [action.payload.productId]: {
                        ...state.domainPublicationConfigurations[action.payload.productId],
                        isFetching: true,
                        didInvalidate: true,
                    },
                },
            };

        case '@PRODUCTS/FETCH_DOMAIN_PUBLICATION_CONFIGURATION_SUCCESS':
            return {
                ...state,
                domainPublicationConfigurations: {
                    ...state.domainPublicationConfigurations,
                    [action.payload.productId]: {
                        ...state.domainPublicationConfigurations[action.payload.productId],
                        isFetching: false,
                        didInvalidate: false,
                        data: action.payload.configuration,
                    },
                },
            };

        case '@PRODUCTS/FETCH_DOMAIN_PUBLICATION_CONFIGURATION_FAILURE':
            return {
                ...state,
                domainPublicationConfigurations: {
                    ...state.domainPublicationConfigurations,
                    [action.payload.productId]: {
                        ...state.domainPublicationConfigurations[action.payload.productId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@PRODUCTS/DEACTIVATE_PRODUCT':
            return {
                ...state,
                deactivate: {
                    ...state.deactivate,
                    [action.payload.productId]: true,
                },
            };

        case '@PRODUCTS/DEACTIVATE_PRODUCT_SUCCESS':
            return {
                ...state,
                deactivate: {
                    ...state.deactivate,
                    [action.payload.productId]: false,
                },
                products: createFetchProductsState(
                    state,
                    [action.payload.productId],
                    {
                        isFetching: false,
                        didInvalidate: true,
                    }),
                searchProducts: {
                    ...state.searchProducts,
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@PRODUCTS/DEACTIVATE_PRODUCT_FAILURE':
            return {
                ...state,
                deactivate: {
                    ...state.deactivate,
                    [action.payload.productId]: false,
                },
            };

        case '@PRODUCTS/REACTIVATE_PRODUCT':
            return {
                ...state,
                reactivate: {
                    ...state.reactivate,
                    [action.payload.productId]: true,
                },
            };

        case '@PRODUCTS/REACTIVATE_PRODUCT_SUCCESS':
            return {
                ...state,
                reactivate: {
                    ...state.reactivate,
                    [action.payload.productId]: false,
                },
                products: createFetchProductsState(
                    state,
                    [action.payload.productId],
                    {
                        isFetching: false,
                        didInvalidate: true,
                    }),
                searchProducts: {
                    ...state.searchProducts,
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@PRODUCTS/REACTIVATE_PRODUCT_FAILURE':
            return {
                ...state,
                reactivate: {
                    ...state.reactivate,
                    [action.payload.productId]: false,
                },
            };

        case '@PRODUCTS/FETCH_SUBSCRIBERS_COUNT_BY_PRODUCT':
            return {
                ...state,
                subscribersCountForProducts: {
                    ...state.subscribersCountForProducts,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@PRODUCTS/FETCH_SUBSCRIBERS_COUNT_BY_PRODUCT_SUCCESS':
            return {
                ...state,
                subscribersCountForProducts: {
                    ...state.subscribersCountForProducts,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.subscribersCountForProducts,
                },
            };

        case '@PRODUCTS/FETCH_SUBSCRIBERS_COUNT_BY_PRODUCT_FAILURE':
            return {
                ...state,
                subscribersCountForProducts: {
                    ...state.subscribersCountForProducts,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        default:
            return state;
    }
};

const createFetchProductsState = (state: ProductsState, productIds: number[], apiRequest: ApiRequest) => {
    let stateProducts = {
        ...state.products,
    };

    for (const productId of productIds) {
        stateProducts = {
            ...stateProducts,
            [productId]: {
                ...state.products[productId],
                ...apiRequest,
            },
        };
    }
    return stateProducts;
};
