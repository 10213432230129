import { AnalystsAction } from './Analysts/AnalystsActions';
import { AnalystsReducer } from './Analysts/AnalystsReducer';
import { AppContextAction } from './AppContext/AppContextActions';
import { AppContextReducer } from './AppContext/AppContextReducer';
import { AsyncTypeaheadAction } from './AsyncTypeahead/AsyncTypeaheadAction';
import { AsyncTypeaheadReducer } from './AsyncTypeahead/AsyncTypeaheadReducer';
import { CategoriesAction } from './Categories/CategoriesAction';
import { CategoriesReducer } from './Categories/CategoriesReducer';
import { KeywordsAction } from './Keywords/KeywordsAction';
import { KeywordsReducer } from './Keywords/KeywordsReducer';
import { ModalsAction } from './Modals/ModalsActions';
import { ModalsReducer } from './Modals/ModalsReducer';
import { NormalizrAction } from './Normalizr/NormalizrAction';
import { NormalizrReducer } from './Normalizr/NormalizrReducer';
import { PersonsAction } from './Persons/PersonsActions';
import { PersonsReducer } from './Persons/PersonsReducer';
import { ProductsAction } from './Products/ProductsActions';
import { ProductsReducer } from './Products/ProductsReducer';
import { ReferentialAction } from './Referential/ReferentialActions';
import { ReferentialReducer } from './Referential/ReferentialReducer';
import { ThirdsAction } from './Thirds/ThirdsActions';
import { ThirdsReducer } from './Thirds/ThirdsReducer';
import { GroupsAction } from 'store/Groups/GroupsActions';
import { GroupsReducer } from 'store/Groups/GroupsReducer';
import { SendersAction } from 'store/Senders/SendersActions';
import { SendersReducer } from 'store/Senders/SendersReducer';
import { ComplianceAction } from 'store/Compliance/ComplianceActions';
import { ComplianceReducer } from 'store/Compliance/ComplianceReducer';


export type Action =
    | AnalystsAction
    | AppContextAction
    | AsyncTypeaheadAction
    | CategoriesAction
    | ComplianceAction
    | GroupsAction
    | KeywordsAction
    | ModalsAction
    | NormalizrAction
    | PersonsAction
    | ProductsAction
    | ReferentialAction
    | SendersAction
    | ThirdsAction
    ;

const reducers = {
    analysts: AnalystsReducer,
    appContext: AppContextReducer,
    asyncTypeahead: AsyncTypeaheadReducer,
    categories: CategoriesReducer,
    compliance: ComplianceReducer,
    entities: NormalizrReducer,
    groups: GroupsReducer,
    keywords: KeywordsReducer,
    modals: ModalsReducer,
    persons: PersonsReducer,
    products: ProductsReducer,
    referential: ReferentialReducer,
    senders: SendersReducer,
    thirds: ThirdsReducer,
};

type StateReducersMapObject = typeof reducers;
type StateKeys = keyof StateReducersMapObject;
export type State = { [key in StateKeys]: ReturnType<StateReducersMapObject[key]> };

export type Reducer<S = any, A = Action | { type: 'TEST' }> = (
    state: S | undefined,
    action: A
) => S

export type ReducersMapObject<S = any, A = Action | { type: 'TEST' }> = {
    [K in keyof S]: Reducer<S[K], A>
}

export type StateFromReducersMapObject<M> = M extends ReducersMapObject
    ? { [P
        in keyof M]: M[P] extends Reducer<infer S, any> ? S : never }
    : never

declare const $CombinedState: unique symbol;
interface EmptyObject {
    readonly [$CombinedState]?: undefined
}
export type CombinedState<S> = EmptyObject & S


export function combineReducers<S>(reducers: ReducersMapObject<S, any>
): Reducer<CombinedState<S>>
export function combineReducers(reducers: ReducersMapObject) {
    const reducerKeys = Object.keys(reducers);
    const finalReducers: ReducersMapObject = {};
    for (let i = 0; i < reducerKeys.length; i++) {
        const key = reducerKeys[i];
        if (typeof reducers[key] === 'function') {
            finalReducers[key] = reducers[key];
        }
    }
    const finalReducerKeys = Object.keys(finalReducers);

    return function combination(
        state: StateFromReducersMapObject<typeof reducers> = {},
        action: Action,
    ) {

        let hasStateChanged = false;
        const nextState: StateFromReducersMapObject<typeof reducers> = {};
        for (let i = 0; i < finalReducerKeys.length; i++) {
            const key = finalReducerKeys[i];
            const reducer = finalReducers[key];
            const previousStateForKey = state[key];
            const nextStateForKey = reducer(previousStateForKey, action);
            if (typeof nextStateForKey === 'undefined') {
                const actionType = action && action.type;
                throw new Error(
                    `When called with an action of type ${actionType ? `"${String(actionType)}"` : '(unknown type)'
                    }, the slice reducer for key "${key}" returned undefined. ` +
                    'To ignore an action, you must explicitly return the previous state. ' +
                    'If you want this reducer to hold no value, you can return null instead of undefined.',
                );
            }
            nextState[key] = nextStateForKey;
            hasStateChanged = hasStateChanged || nextStateForKey !== previousStateForKey;
        }
        hasStateChanged =
            hasStateChanged || finalReducerKeys.length !== Object.keys(state).length;
        return hasStateChanged ? nextState : state;
    };
}

export const reducer = combineReducers<State>(reducers);

export const initialState = reducer({} as State, {} as any);
