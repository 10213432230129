import { TFunction } from 'i18next';
import { generatePath } from 'react-router-dom';
import {
    KeywordFilter,
    ProductFilter,
    SearchAnalystsRequest,
    SearchCategoriesRequest,
    SearchGroupsRequest,
    SearchSendersRequest,
    SearchThirdsRequest,
} from 'services/ApiService/InsightBackoffice/InsightBackofficeApiClient';
import { DEFAULT_SEARCH_ANALYSTS_FILTERS } from 'store/Analysts/AnalystsReducer';
import { DEFAULT_SEARCH_THIRDS_FILTERS } from 'store/Thirds/ThirdsReducer';
import { DEFAULT_SEARCH_GROUPS_FILTERS } from 'store/Groups/GroupsReducer';
import { DEFAULT_SEARCH_PRODUCT_FILTERS } from 'store/Products/ProductsReducer';
import { DEFAULT_SEARCH_SENDERS_FILTERS } from 'store/Senders/SendersReducer';
import { DEFAULT_SEARCH_KEYWORD_FILTERS } from 'store/Keywords/KeywordsReducer';
import { DEFAULT_SEARCH_CATEGORIES_FILTERS } from 'store/Categories/CategoriesReducer';

export enum CompliancePage {
    ManualDisclosures = 'manual-disclosures',
    PredefinedDisclosures = 'predefined-disclosures',
}

export const RoutePaths = {
    Errors: {
        NotAuthorized: {
            route: '/error/not-authorized',
            url: () => RoutePaths.Errors.NotAuthorized.route,
        },
        NotAuthorizedContactUs: {
            route: '/error/not-authorized/contact-us',
            url: () => RoutePaths.Errors.NotAuthorizedContactUs.route,
        },
        NotFound: {
            route: '*',
            url: () => '/error/not-found',
        },
    },
    ResearchCompanies: {
        title: (t: TFunction<'translation', undefined>) => t('menu:Titles.Research companies'),
        AllCompanies: {
            route: '/',
            url: (filters?: Partial<SearchThirdsRequest>, startGuideTour?: string) => RoutePaths.ResearchCompanies.AllCompanies.route + getQueryParameters({
                skip: filters?.skip,
                take: filters?.take === DEFAULT_SEARCH_THIRDS_FILTERS.take ? undefined : filters?.take,
                search: filters?.searchTerms,
                startGuideTour,
            }),
            title: (t: TFunction<'translation', undefined>) => t('menu:Titles.All companies'),
            Create: {
                route: '/third/create',
                url: () => RoutePaths.ResearchCompanies.AllCompanies.Create.route,
                title: (t: TFunction<'translation', undefined>) => t('menu:Titles.New company'),
            },
            New: {
                route: '/third/new',
                url: () => RoutePaths.ResearchCompanies.AllCompanies.New.route,
                title: (t: TFunction<'translation', undefined>) => t('menu:Titles.New company'),
            },
            Edit: {
                route: '/third/:thirdId/edit',
                url: (thirdId: number) => generatePath(RoutePaths.ResearchCompanies.AllCompanies.Edit.route, { thirdId: `${thirdId}` }),
                title: (t: TFunction<'translation', undefined>) => t('menu:Titles.Edit company'),
                Compliance: {
                    route: '/third/:thirdId/edit/compliance',
                    url: (thirdId: number) => generatePath(RoutePaths.ResearchCompanies.AllCompanies.Edit.Compliance.route, { thirdId: `${thirdId}` }),
                    title: (t: TFunction<'translation', undefined>) => t('menu:Titles.Edit company.Compliance'),
                    dropdownTitle: (t: TFunction<'translation', undefined>) => t('menu:Titles.CompanyMenu.Compliance'),
                },
            },
            View: {
                route: '/third/:thirdId/view',
                url: (thirdId: number) => generatePath(RoutePaths.ResearchCompanies.AllCompanies.View.route, { thirdId: `${thirdId}` }),
                title: (t: TFunction<'translation', undefined>) => t('menu:Titles.View company'),
                Compliance: {
                    route: '/third/:thirdId/view/compliance',
                    url: (thirdId: number) => generatePath(RoutePaths.ResearchCompanies.AllCompanies.View.Compliance.route, { thirdId: `${thirdId}` }),
                    title: (t: TFunction<'translation', undefined>) => t('menu:Titles.View company.Compliance'),
                    dropdownTitle: (t: TFunction<'translation', undefined>) => t('menu:Titles.CompanyMenu.Compliance'),
                },
            },
        },
    },
    AuthorsAndGroups: {
        title: (t: TFunction<'translation', undefined>) => t('menu:Titles.Authors & groups'),
        Authors: {
            route: '/authors',
            url: (filters?: Partial<SearchAnalystsRequest>) => RoutePaths.AuthorsAndGroups.Authors.route + getQueryParameters({
                skip: filters?.skip,
                take: filters?.take === DEFAULT_SEARCH_ANALYSTS_FILTERS.take ? undefined : filters?.take,
                search: filters?.searchTerms,
                onlyActive: filters?.onlyActive === false ? 'false' : undefined,
            }),
            title: (t: TFunction<'translation', undefined>) => t('menu:Titles.Authors'),
            Create: {
                route: '/authors/create',
                url: () => RoutePaths.AuthorsAndGroups.Authors.Create.route,
                title: (t: TFunction<'translation', undefined>) => t('menu:Titles.New analyst'),
            },
            Update: {
                route: '/authors/:analystId/update',
                url: (analystId: string) => generatePath(RoutePaths.AuthorsAndGroups.Authors.Update.route, { analystId: `${analystId}` }),
                title: (t: TFunction<'translation', undefined>) => t('menu:Titles.Edit analyst'),
                dropdownTitle: (t: TFunction<'translation', undefined>) => t('menu:Titles.AnalystMenu.Edit'),
            },
        },
        Groups: {
            route: '/groups',
            url: (filters?: Partial<SearchGroupsRequest>) => RoutePaths.AuthorsAndGroups.Groups.route + getQueryParameters({
                skip: filters?.skip,
                take: filters?.take === DEFAULT_SEARCH_GROUPS_FILTERS.take ? undefined : filters?.take,
                search: filters?.searchTerms,
                onlyActive: filters?.onlyActive === false ? 'false' : undefined,
                onlyResearchGroups: filters?.onlyResearchGroups === false ? 'false' : undefined,
            }),
            title: (t: TFunction<'translation', undefined>) => t('menu:Titles.Groups'),
            Users: {
                route: '/groups/:groupId/users',
                url: (groupId: number) => generatePath(RoutePaths.AuthorsAndGroups.Groups.Users.route, { groupId: `${groupId}` }),
            },
        },
    },
    ProductsAndSubscriptions: {
        title: (t: TFunction<'translation', undefined>) => t('menu:Titles.Products & subscriptions'),
        Products: {
            route: '/products',
            url: (filters?: Partial<ProductFilter>) => RoutePaths.ProductsAndSubscriptions.Products.route + getQueryParameters({
                skip: filters?.skip,
                take: filters?.take === DEFAULT_SEARCH_PRODUCT_FILTERS.take ? undefined : filters?.take,
                search: filters?.searchTerms,
                onlyActive: filters?.includeInactive === true ? 'false' : undefined,
            }),
            title: (t: TFunction<'translation', undefined>) => t('menu:Titles.Products'),
            Create: {
                route: '/products/create',
                url: (copyFromProductId?: number) => RoutePaths.ProductsAndSubscriptions.Products.Create.route + getQueryParameters({ copyFromProductId }),
                title: (t: TFunction<'translation', undefined>) => t('menu:Titles.New product'),
                GeneraleProperties: {
                    route: '/products/create/general-properties',
                    url: () => RoutePaths.ProductsAndSubscriptions.Products.Create.GeneraleProperties.route,
                },
                Publication: {
                    route: '/products/create/publication',
                    url: () => RoutePaths.ProductsAndSubscriptions.Products.Create.Publication.route,
                },
                Insight: {
                    route: '/products/create/insight',
                    url: () => RoutePaths.ProductsAndSubscriptions.Products.Create.Insight.route,
                },
                Distribution: {
                    route: '/products/create/distribution',
                    url: () => RoutePaths.ProductsAndSubscriptions.Products.Create.Distribution.route,
                },
            },
            Update: {
                route: '/products/:productId/update',
                url: (productId: number) => generatePath(RoutePaths.ProductsAndSubscriptions.Products.Update.route, { productId: `${productId}` }),
                dropdownTitle: (t: TFunction<'translation', undefined>) => t('menu:Titles.ProductsMenu.Edit'),
                Publication: {
                    route: '/products/:productId/update/publication',
                    url: (productId: number) => generatePath(RoutePaths.ProductsAndSubscriptions.Products.Update.Publication.route, { productId: `${productId}` }),
                },
                Insight: {
                    route: '/products/:productId/update/insight',
                    url: (productId: number) => generatePath(RoutePaths.ProductsAndSubscriptions.Products.Update.Insight.route, { productId: `${productId}` }),
                },
                Distribution: {
                    route: '/products/:productId/update/distribution',
                    url: (productId: number) => generatePath(RoutePaths.ProductsAndSubscriptions.Products.Update.Distribution.route, { productId: `${productId}` }),
                },
            },
        },
        Senders: {
            route: '/senders',
            url: (filters?: Partial<SearchSendersRequest>) => RoutePaths.ProductsAndSubscriptions.Senders.route + getQueryParameters({
                skip: filters?.skip,
                take: filters?.take === DEFAULT_SEARCH_SENDERS_FILTERS.take ? undefined : filters?.take,
                search: filters?.searchTerms,
                onlyActive: filters?.onlyActive === false ? 'false' : undefined,
            }),
            title: (t: TFunction<'translation', undefined>) => t('menu:Titles.Senders'),
        },
        Keywords: {
            route: '/keywords',
            url: (filters?: Partial<KeywordFilter>) => RoutePaths.ProductsAndSubscriptions.Keywords.route + getQueryParameters({
                skip: filters?.skip,
                take: filters?.take === DEFAULT_SEARCH_KEYWORD_FILTERS.take ? undefined : filters?.take,
                search: filters?.searchTerms,
                onlyActive: filters?.includeInactive === true ? 'false' : undefined,
            }),
            title: (t: TFunction<'translation', undefined>) => t('menu:Titles.Keywords'),
        },
        ResearchCategories: {
            route: '/categories',
            url: (filters?: Partial<SearchCategoriesRequest>) => RoutePaths.ProductsAndSubscriptions.ResearchCategories.route + getQueryParameters({
                skip: filters?.skip,
                take: filters?.take === DEFAULT_SEARCH_CATEGORIES_FILTERS.take ? undefined : filters?.take,
                search: filters?.searchTerms,
                onlyActive: filters?.onlyActive === false ? 'false' : undefined,
            }),
            title: (t: TFunction<'translation', undefined>) => t('menu:Titles.Research Categories'),
            Create: {
                route: '/categories/create',
                url: () => RoutePaths.ProductsAndSubscriptions.ResearchCategories.Create.route,
                title: (t: TFunction<'translation', undefined>) => t('menu:Titles.New research category'),
            },
            Update: {
                route: '/categories/:categoryId/update',
                url: (categoryId: number) => generatePath(RoutePaths.ProductsAndSubscriptions.ResearchCategories.Update.route, { categoryId: `${categoryId}` }),
                dropdownTitle: (t: TFunction<'translation', undefined>) => t('menu:Titles.CategoriesMenu.Edit'),
            },
        },
    },
} as const;

export type RouteParams = {
    Errors: {
        NotAuthorized: ExtractRouteParams<typeof RoutePaths.Errors.NotAuthorized.route, string>,
        NotFound: ExtractRouteParams<typeof RoutePaths.Errors.NotFound.route, string>,
    },
    ResearchCompanies: {
        AllCompanies: ExtractRouteParams<typeof RoutePaths.ResearchCompanies.AllCompanies.route, string>,
        Edit: ExtractRouteParams<typeof RoutePaths.ResearchCompanies.AllCompanies.Edit.route, string>,
        View: ExtractRouteParams<typeof RoutePaths.ResearchCompanies.AllCompanies.View.route, string>,
        Compliance: ExtractRouteParams<typeof RoutePaths.ResearchCompanies.AllCompanies.Edit.Compliance.route, string>,
    },
    AuthorsAndGroups: {
        Authors: ExtractRouteParams<typeof RoutePaths.AuthorsAndGroups.Authors.route, string>,
        CreateAuthor: ExtractRouteParams<typeof RoutePaths.AuthorsAndGroups.Authors.Create.route, string>,
        UpdateAuthor: ExtractRouteParams<typeof RoutePaths.AuthorsAndGroups.Authors.Update.route, string>,
        Groups: ExtractRouteParams<typeof RoutePaths.AuthorsAndGroups.Groups.route, string>,
        GroupUsers: ExtractRouteParams<typeof RoutePaths.AuthorsAndGroups.Groups.Users.route, string>,
    },
    ProductsAndSubscriptions: {
        Products: ExtractRouteParams<typeof RoutePaths.ProductsAndSubscriptions.Products.route, string>,
        Senders: ExtractRouteParams<typeof RoutePaths.ProductsAndSubscriptions.Senders.route, string>,
        Keywords: ExtractRouteParams<typeof RoutePaths.ProductsAndSubscriptions.Keywords.route, string>,
        ResearchCategories: ExtractRouteParams<typeof RoutePaths.ProductsAndSubscriptions.ResearchCategories.route, string>,
        UpdateResearchCategories: ExtractRouteParams<typeof RoutePaths.ProductsAndSubscriptions.ResearchCategories.Update.route, string>,
    }
};

const getQueryParameters = (parameters: Record<string, string | number | boolean | undefined | Date | (string | number | boolean | undefined | Date)[]>) => {
    const search = new URLSearchParams(
        Object.entries(parameters)
            .filter(entry => entry[1])
            .map(([key, value]) => ([key, value?.toString() || ''])),
    ).toString();
    return search ? `?${search}` : '';
};


type ExtractRouteOptionalParam<T extends string, U = string | number | boolean> = T extends `${infer Param}?`
    ? { [_ in Param]?: U }
    : T extends `${infer Param}*`
    ? { [_ in Param]?: U }
    : T extends `${infer Param}+`
    ? { [_ in Param]: U }
    : { [_ in T]: U };

/* eslint-disable */
type ExtractRouteParams<T extends string, U = string | number | boolean> = string extends T
    ? { [_ in string]?: U }
    : T extends `${infer _}:${infer ParamWithOptionalRegExp}/${infer Rest}`
    ? ParamWithOptionalRegExp extends `${infer Param}(${infer _})`
    ? ExtractRouteOptionalParam<Param, U> & ExtractRouteParams<Rest, U>
    : ExtractRouteOptionalParam<ParamWithOptionalRegExp, U> & ExtractRouteParams<Rest, U>
    : T extends `${infer _}:${infer ParamWithOptionalRegExp}`
    ? ParamWithOptionalRegExp extends `${infer Param}(${infer _})`
    ? ExtractRouteOptionalParam<Param, U>
    : ExtractRouteOptionalParam<ParamWithOptionalRegExp, U>
    : unknown;
