import React from 'react';
import { NavLink } from 'react-router-dom';
import { RoutePaths } from 'routes/RoutePaths';
import { track, TrackedPageType } from 'services/Logger';

export const HeaderLogo = () => {
    const handleClick = () => {
        track('ClickOnMenu', 'click on menu', { page: TrackedPageType.Menu, pageName: 'Home' });
    };

    return <NavLink
        end
        to={RoutePaths.ResearchCompanies.AllCompanies.url()}
        className="navbar-title-link"
        onMouseUp={() => handleClick()}
    >
        <div className="navbar-logo">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0" y="0" width="100%" height="50%" fill="#E60028"></rect>
                <rect x="0" y="50%" width="100%" height="50%" fill="#1D1D1B"></rect>
                <rect x="17%" y="46.5%" width="65%" height="7%" fill="white"></rect>
            </svg>
        </div>
        <div className="navbar-title-divider"></div>
        <div className="navbar-service-name">Insight Backoffice</div>
    </NavLink>;
};