import { setupSGWTConnect, SGWTConnectCore } from '@sgwt/connect-core';
import { configuration } from 'config/constants';

declare global {
    interface Window {
        sgwtConnect?: SGWTConnectCore;
        SGWTWidgetConfiguration: { environment: SgConnectEnvironment }
    }
}

export enum SgConnectEnvironment {
    Homologation = 'homologation',
    Production = 'production',
}

export interface SgConnectSettings {
    authorizationEndpoint: string;
    clientId: string;
    scope: string;
    environment: SgConnectEnvironment;
}

export let sgConnect: SGWTConnectCore | undefined;

export const redirectUnauthorizedUser = () => {
    return window.location.replace(configuration.externalLinks.requestAccessUrl());
};

export const getAuthorizationHeader = (): string => loadSGConnect()?.getAuthorizationHeader?.() ?? '';

export const initSGConnect = (): boolean => {
    const settings = getSGConnectSettings();

    const sgConnect = loadSGConnect(settings);

    setupPassiveMode(sgConnect);

    if (sgConnect.isAuthorized()) {
        return true;
    }

    const authorizationError = sgConnect.getAuthorizationError();
    if (authorizationError) {
        throw authorizationError;
    }

    return false;
};

export const loadSGConnect = (settings: SgConnectSettings = getSGConnectSettings()) => {
    if (!settings.authorizationEndpoint) {
        throw new Error('[loadSGConnect] Missing authorizationEndpoint');
    }
    if (!settings.clientId) {
        throw new Error('[loadSGConnect] Missing clientId');
    }

    const isWindowDefined = typeof window !== 'undefined';
    if (isWindowDefined && !window.sgwtConnect) {
        window.SGWTWidgetConfiguration = {
            ...window.SGWTWidgetConfiguration,
            environment: settings.environment,
        };
    }

    if (!isWindowDefined || !window.sgwtConnect) {
        sgConnect = setupSGWTConnect({
            authorization_endpoint: settings?.authorizationEndpoint,
            client_id: settings?.clientId,
            scope: settings?.scope,
            acr_values: 'L1',
        });
        if (isWindowDefined) {
            window.sgwtConnect = sgConnect;
        }
    } else {
        sgConnect = window.sgwtConnect;
    }

    return sgConnect;
};

export const setupPassiveMode = (sgwtConnect: SGWTConnectCore) => {
    const isDocumentDefined = typeof document !== 'undefined';
    const widget = isDocumentDefined && document.querySelector('sgwt-connect') as any;
    if (!widget || typeof widget.setSgwtConnectInstance === 'undefined') {
        setTimeout(() => setupPassiveMode(sgwtConnect), 100);
        return;
    }

    widget.setSgwtConnectInstance(sgwtConnect);
};

const getSGConnectSettings = () => {
    return {
        authorizationEndpoint: configuration.authorizationEndpoint,
        clientId: configuration.clientId,
        scope: configuration.scope,
        environment: configuration.sgConnectEnvironment,
    };
};
