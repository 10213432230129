import classNames from 'classnames';
import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface ILoadingProps {
    text?: string;
    className?: string;
    visible?: boolean;
    useChildrenAsText?: boolean;
    style?: React.CSSProperties;
    children?: React.ReactNode;
    size?: 'sm' | 'md' | 'lg' | 'xl';
    spinnerOnly?: boolean;
}

export const Loading: FC<ILoadingProps> = ({ text, className, style, visible = true, size, children, useChildrenAsText = false, spinnerOnly = false }) => {
    const { t } = useTranslation();
    if (!visible) {
        return <>{children}</>;
    }
    return <div className={classNames('d-flex align-items-center', className)} style={style}>
        <div className={classNames('spinner me-2', { [`spinner-${size}`]: size })} role="status" aria-hidden="true"></div>
        {spinnerOnly ? undefined : <strong> {useChildrenAsText ? children : text || t('common:Loading.Loading')} </strong>}
    </div>;
};

export interface IServiceLoaderProps {
    visible?: boolean;
    children?: React.ReactNode;
}

export const ServiceLoader: FC<IServiceLoaderProps> = ({ visible = true, children }) => {
    if (!visible) {
        return <>{children}</>;
    }
    return <div className="service-loader d-flex flex-column position-absolute">
        <div className="service-loader-logo"></div>
        <div className="progress progress-sm progress-container">
            <div className="progress-bar animated-progress" role="progressbar"></div>
        </div>
    </div>;
};
