import i18n, { TFunction } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { printValue, setLocale } from 'yup';
import backend from './backend';

export const configureI18n = async (messages?: Record<string, Record<string, object>>, locales?: Record<string, string>) => {
    await i18n
        .use(backend)
        .use(initReactI18next)
        .init(
            {
                backend: {
                    loadPath: (lang: string) => locales?.[lang] ? `/${locales[lang]}` : undefined,
                },
                fallbackLng: 'en',
                interpolation: {
                    escapeValue: false,
                },
                load: 'languageOnly',
            },
            buildYupLocale);

    if (messages) {
        Object.keys(messages)
            .forEach((lang) => {
                Object.keys(messages[lang])
                    .forEach((ns) => {
                        i18n.addResourceBundle(lang, ns, messages[lang][ns]);
                    });
            });
    }
};

export const buildYupLocale = (_: unknown, t: TFunction): void => {
    setLocale({
        mixed: {
            default: (values) => t('validation:mixed.default', { ...values, label: values.label || values.path }),
            required: (values) => t('validation:mixed.required', { ...values, label: values.label || values.path }),
            defined: (values) => t('validation:mixed.defined', { ...values, label: values.label || values.path }),
            notNull: (values) => t('validation:mixed.notNull', { ...values, label: values.label || values.path }),
            oneOf: (values) => t('validation:mixed.oneOf', { ...values, label: values.label || values.path }),
            notOneOf: (values) => t('validation:mixed.notOneOf', { ...values, label: values.label || values.path }),
            notType: ({ path, type, value, originalValue }) => {
                const castFromValue = printValue(originalValue, true);
                const castMsg =
                    originalValue != null && originalValue !== value
                        ? t('cast from the value', { castFromValue })
                        : '.';

                const validatedValue = printValue(value, true);
                return type !== 'mixed'
                    ? t('must be type', { path, type, validatedValue, castMsg })
                    : t('must match', { path, validatedValue, castMsg });
            },
        },
        number: {
            min: (values) => t('validation:number.min', { ...values, label: values.label || values.path }),
            max: (values) => t('validation:number.max', { ...values, label: values.label || values.path }),
            lessThan: (values) => t('validation:number.lessThan', { ...values, label: values.label || values.path }),
            moreThan: (values) => t('validation:number.moreThan', { ...values, label: values.label || values.path }),
            positive: (values) => t('validation:number.positive', { ...values, label: values.label || values.path }),
            negative: (values) => t('validation:number.negative', { ...values, label: values.label || values.path }),
            integer: (values) => t('validation:number.integer', { ...values, label: values.label || values.path }),
        },
        string: {
            length: (values) => t('validation:string.length', { ...values, label: values.label || values.path }),
            min: (values) => t('validation:string.min', { ...values, label: values.label || values.path }),
            max: (values) => t('validation:string.max', { ...values, label: values.label || values.path }),
            matches: (values) => t('validation:string.matches', { ...values, label: values.label || values.path }),
            email: (values) => t('validation:string.email', { ...values, label: values.label || values.path }),
            url: (values) => t('validation:string.url', { ...values, label: values.label || values.path }),
            uuid: (values) => t('validation:string.uuid', { ...values, label: values.label || values.path }),
            trim: (values) => t('validation:string.trim', { ...values, label: values.label || values.path }),
            lowercase: (values) => t('validation:string.lowercase', { ...values, label: values.label || values.path }),
            uppercase: (values) => t('validation:string.uppercase', { ...values, label: values.label || values.path }),
        },
        date: {
            min: (values) => t('validation:date.min', { ...values, label: values.label || values.path }),
            max: (values) => t('validation:date.max', { ...values, label: values.label || values.path }),
        },
        boolean: {
            isValue: (values) => t('validation:boolean.isValue', { ...values, label: values.label || values.path }),
        },
        object: {
            noUnknown: (values) => t('validation:object.noUnknown', { ...values, label: values.label || values.path }),
        },
        array: {
            min: (values) => t('validation:array.min', { ...values, label: values.label || values.path }),
            max: (values) => t('validation:array.max', { ...values, label: values.label || values.path }),
            length: (values) => t('validation:array.length', { ...values, label: values.label || values.path }),
        },
    });
};
