import {
    LoggedUser,
} from './ApiService/InsightBackoffice/InsightBackofficeApiClient';

export enum Permissions {
    Administrator = 'Administrator',
    AccessToInsightBackoffice = 'Access to Insight Backoffice',
    ManageCompanyDocumentData = 'Manage company document data',
    ManageComplianceData = 'Manage compliance data',
}

const convertUserPermissions = (user?: LoggedUser | null): Permissions[] => {
    if (!user) {
        return [];
    }

    if (user.permissions && user.permissions.length) {
        return user.permissions.map((up) => up.name as Permissions);
    }
    return [];
};

export const hasRight = (user?: LoggedUser | null, permission?: Permissions): boolean => {
    const userPermissions = convertUserPermissions(user);
    if (userPermissions && userPermissions.length) {
        return !!userPermissions.find((up) => up === permission);
    }
    return false;
};

export const hasAnyRight = (user: LoggedUser | null | undefined, permissions: Permissions[]): boolean => {
    const userPermissions = convertUserPermissions(user);
    if (userPermissions && userPermissions.length) {
        return !!userPermissions.some((up) => permissions.includes(up));
    }
    return false;
};

export const hasAccess = (user: LoggedUser | null | undefined) => {
    return hasRight(user, Permissions.AccessToInsightBackoffice);
};