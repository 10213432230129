import { SgConnectEnvironment } from 'services/SgConnect';

export enum Environment {
    Local = 'Local',
    Development = 'Development',
    Homologation = 'Homologation',
    Production = 'Production',
    Test = 'Test',
}

export const getEnvironment = () => {
    let environment = Environment.Production;
    if (typeof window === 'undefined' || !window.location.hostname) {
        environment = Environment.Test;
    } else if (window.location.hostname === 'local.fr.world.socgen'
        || window.location.hostname === 'localhost') {
        environment = Environment.Local;
    } else if (window.location.hostname === 'insight-backoffice-dev.sgmarkets.com') {
        environment = Environment.Development;
    } else if (window.location.hostname === 'insight-backoffice-uat.sgmarkets.com') {
        environment = Environment.Homologation;
    } else if (window.location.hostname === 'insight-backoffice.sgmarkets.com'
        || window.location.hostname === 'insight-backoffice-preview.sgmarkets.com') {
        environment = Environment.Production;
    }
    return environment;
};

interface IConfiguration {
    baseApiUrls: {
        insightBackoffice: string;
    };
    externalLinks: {
        requestAccessUrl: (returnUrl?: string, sourceUrl?: string) => string;
    },
    authorizationEndpoint: string;
    clientId: string;
    scope: string;
    sgConnectEnvironment: SgConnectEnvironment;
    widgetCdnBaseUrl: string;
    sgMonitoringRumUrl: string;
    sharedSgMarketsBaseUrl: string;
}

const defaultConfiguration = {
    baseApiUrls: {
        insightBackoffice: 'https://pretrade-api-insight-backoffice-dev.sgmpinsight.dev.euw.gbis.sg-azure.com',
    },
    externalLinks: {
        requestAccessUrl: (returnUrl = window.location.href, sourceUrl = window.location.origin) =>
            `https://info-uat.sgmarkets.com/en/request-access?returnurl=${returnUrl}&sourceurl=${sourceUrl}&sourcecontext=InsightBackoffice`,
    },
    clientId: '3e3f5abf-1a8f-4189-86aa-0afd4617a9be',
    authorizationEndpoint: 'https://sgconnect-hom.fr.world.socgen/sgconnect',
    scope: [
        'mail',
        'openid',
        'profile',
        'api.insight-backoffice.v1',
        'api.manage-contacts.v1',
    ].join(' '),
    sgConnectEnvironment: SgConnectEnvironment.Homologation,
    widgetCdnBaseUrl: 'https://sgwt-widgets-dev.sgmarkets.com',
    sgMonitoringRumUrl: 'https://t-monitoring-rum-uat.sgmarkets.com/rum/817315b0bcda4502ab8ff5d574eb0d32',
    sharedSgMarketsBaseUrl: 'https://shared-uat.sgmarkets.com',
};

export const configurationPerEnvironment: Record<Environment, IConfiguration> = {
    [Environment.Local]: {
        ...defaultConfiguration,
    },
    [Environment.Development]: {
        ...defaultConfiguration,
        baseApiUrls: {
            insightBackoffice: 'https://pretrade-api-insight-backoffice-dev.sgmpinsight.dev.euw.gbis.sg-azure.com',
        },
    },
    [Environment.Homologation]: {
        ...defaultConfiguration,
        baseApiUrls: {
            insightBackoffice: 'https://pretrade-api-insight-backoffice-uat.sgmpinsight.dev.euw.gbis.sg-azure.com',
        },
        externalLinks: {
            ...defaultConfiguration.externalLinks,
        },
    },
    [Environment.Production]: {
        ...defaultConfiguration,
        baseApiUrls: {
            insightBackoffice: 'https://pretrade-api-insight-backoffice-prd.sgmpinsight.prd.euw.gbis.sg-azure.com',
        },
        externalLinks: {
            ...defaultConfiguration.externalLinks,
            requestAccessUrl: (returnUrl = window.location.origin, sourceUrl = window.location.hostname) =>
                `https://info.sgmarkets.com/en/request-access?returnurl=${returnUrl}&sourceurl=${sourceUrl}&sourcecontext=InsightBackoffice`,
        },
        clientId: '67d06827-86c9-4770-a702-dbf2f13a26ff',
        authorizationEndpoint: 'https://sso.sgmarkets.com/sgconnect/oauth2/authorize',
        sgConnectEnvironment: SgConnectEnvironment.Production,
        widgetCdnBaseUrl: 'https://sgwt-cdn-widgets.sgmarkets.com',
        sgMonitoringRumUrl: 'https://t-monitoring-rum.sgmarkets.com/rum/540a239031b64d7fbe34fe54fef041db',
        sharedSgMarketsBaseUrl: 'https://shared.sgmarkets.com/',
    },
    [Environment.Test]: {
        ...defaultConfiguration,
    },
};

export const configuration = configurationPerEnvironment[getEnvironment()];
