import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'react-app-polyfill/ie11';

import 'bootstrap';
import { configureI18n } from 'config/i18n';
import { configureRum } from 'config/rum';
import { AppProvider } from 'components/common/AppProvider';
import { ContextualTour, ContextualTourProvider } from 'components/common/ContextualTour';
import { ErrorBoundary } from 'components/common/ErrorBoundary';
import { MaterialUiProvider } from 'components/common/MaterialUiProvider';
import { ScrollToTop } from 'components/common/ScrollToTop';
import i18n from 'i18next';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { initSGConnect } from 'services/SgConnect';
import { messages, locales } from 'virtual-vite-i18n-resources';
import { AppRoutes } from 'routes';


import './css/bootstrapTheming.scss';
import './css/site.scss';

async function start() {
    const App = (children: React.ReactNode) => <AppProvider>
        <MaterialUiProvider>
            <ErrorBoundary>
                <BrowserRouter>
                    <ScrollToTop />
                    <ContextualTourProvider>
                        <ContextualTour />
                        {children}
                    </ContextualTourProvider>
                </BrowserRouter>
            </ErrorBoundary>
        </MaterialUiProvider>
    </AppProvider>;

    const renderApp = () => {
        const container = document.getElementById('root');

         
        const root = createRoot(container!);
        root.render(App((<AppRoutes />)));
        const appInitLoader = document.getElementById('app-init-loader');
        if (appInitLoader) {
            appInitLoader.remove();
        }
    };

    initSGConnect();

    await configureI18n(messages, locales);
    if (import.meta?.hot) {
        import.meta.hot.on('locales-update', (data: Record<string, Record<string, object>>) => {
            Object.keys(data)
                .forEach((lang) => {
                    Object.keys(data[lang])
                        .forEach((ns) => {
                            i18n.addResourceBundle(lang, ns, data[lang][ns]);
                        });
                });
        });
    }

    configureRum();

    renderApp();
}

start();
