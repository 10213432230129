import { CreateKeywordResponse, KeywordConfig, KeywordFilter, KeywordFilterOrderBy, ResearchCategorySubscriptionConfig, UpdateKeywordResponse } from 'services/ApiService/InsightBackoffice/InsightBackofficeApiClient';
import { ApiRequest, Reducer } from 'store';

export const DEFAULT_SEARCH_KEYWORD_FILTERS: KeywordFilter = {
    skip: 0,
    take: 10,
    includeInactive: false,
    orderBys: [KeywordFilterOrderBy.NameAsc],
};

export const initialState = {
    searchKeywords: {
        filters: {
            ...DEFAULT_SEARCH_KEYWORD_FILTERS,
        },
        total: 0,
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]> & {
        filters: KeywordFilter,
        total: number,
    },
    searchForAdd: {
        filters: {
            ...DEFAULT_SEARCH_KEYWORD_FILTERS,
        },
        total: 0,
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]> & {
        filters: KeywordFilter,
        total: number,
    },
    keywords: {} as { readonly [id: number]: ApiRequest },
    createKeyword: {} as { readonly [name: string]: ApiRequest<CreateKeywordResponse> },
    updateKeyword: {} as { readonly [id: number]: ApiRequest<UpdateKeywordResponse> },
    keywordConfigs: {} as { readonly [id: number]: ApiRequest<KeywordConfig> },
    keywordSubscriptionConfigs: {} as { readonly [id: number]: ApiRequest<ResearchCategorySubscriptionConfig[]> },
    deactivate: {} as { readonly [keywordId: number]: boolean },
    reactivate: {} as { readonly [keywordId: number]: boolean },
} as const;

export type KeywordsState = typeof initialState;

export const KeywordsReducer: Reducer<KeywordsState> = (state = initialState, action) => {
    let isAlreadyDefaultFilter: boolean;
    switch (action.type) {
        case '@KEYWORDS/SET_SEARCH_KEYWORDS_SEARCH_TERMS':
            if (state.searchKeywords.filters.searchTerms?.toLowerCase() === action.payload.searchTerms?.toLowerCase()) {
                return state;
            }
            return {
                ...state,
                searchKeywords: {
                    ...state.searchKeywords,
                    filters: {
                        ...state.searchKeywords.filters,
                        searchTerms: action.payload.searchTerms,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@KEYWORDS/SET_SEARCH_KEYWORDS_ONLY_ACTIVE':
            if (state.searchKeywords.filters.includeInactive !== action.payload.onlyActive) {
                return state;
            }
            return {
                ...state,
                searchKeywords: {
                    ...state.searchKeywords,
                    filters: {
                        ...state.searchKeywords.filters,
                        includeInactive: !action.payload.onlyActive,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@KEYWORDS/SEARCH_KEYWORDS_CHANGE_PAGE':
            if (state.searchKeywords.filters.skip === action.payload.skip && state.searchKeywords.filters.take === action.payload.take) {
                return state;
            }
            return {
                ...state,
                searchKeywords: {
                    ...state.searchKeywords,
                    filters: {
                        ...state.searchKeywords.filters,
                        skip: action.payload.skip,
                        take: action.payload.take,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@KEYWORDS/RESET_SEARCH_KEYWORDS':
            isAlreadyDefaultFilter = Object.entries(state.searchKeywords.filters).every(([filterKey, filterValue]) => {
                switch (filterKey) {
                    case 'skip':
                        return filterValue === 0;
                    case 'take':
                        return filterValue === DEFAULT_SEARCH_KEYWORD_FILTERS.take;
                    case 'onlyActive':
                        return filterValue === DEFAULT_SEARCH_KEYWORD_FILTERS.includeInactive;
                    case 'orderBys':
                        return filterValue === DEFAULT_SEARCH_KEYWORD_FILTERS.orderBys;
                    default:
                        return !filterValue;
                }
            });
            if (isAlreadyDefaultFilter) {
                return state;
            }

            return {
                ...state,
                searchKeywords: {
                    ...state.searchKeywords,
                    filters: {
                        ...DEFAULT_SEARCH_KEYWORD_FILTERS,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@KEYWORDS/SEARCH_KEYWORDS':
            return {
                ...state,
                searchKeywords: {
                    ...state.searchKeywords,
                    didInvalidate: true,
                    isFetching: true,
                },
            };

        case '@KEYWORDS/SEARCH_KEYWORDS_SUCCESS':
            return {
                ...state,
                searchKeywords: {
                    ...state.searchKeywords,
                    didInvalidate: false,
                    isFetching: false,
                    data: action.payload.keywordIds,
                    total: action.payload.total,
                },
                keywords: createFetchKeywordsState(
                    state,
                    action.payload.keywordIds,
                    {
                        isFetching: false,
                        didInvalidate: false,
                    }),
            };

        case '@KEYWORDS/SEARCH_KEYWORDS_FAILURE':
            return {
                ...state,
                searchKeywords: {
                    ...state.searchKeywords,
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@KEYWORDS/SEARCH_KEYWORDS_FOR_ADD':
            return {
                ...state,
                searchForAdd: {
                    ...state.searchForAdd,
                    didInvalidate: true,
                    isFetching: true,
                },
            };

        case '@KEYWORDS/SEARCH_KEYWORDS_FOR_ADD_SUCCESS':
            return {
                ...state,
                searchForAdd: {
                    ...state.searchForAdd,
                    didInvalidate: false,
                    isFetching: false,
                    data: action.payload.ids,
                    total: action.payload.total,
                },
                keywords: createFetchKeywordsState(
                    state,
                    action.payload.ids,
                    {
                        isFetching: false,
                        didInvalidate: false,
                    }),
            };

        case '@KEYWORDS/SEARCH_KEYWORDS_FOR_ADD_FAILURE':
            return {
                ...state,
                searchForAdd: {
                    ...state.searchForAdd,
                    didInvalidate: true,
                    isFetching: false,
                },
            };


        case '@KEYWORDS/SET_SEARCH_KEYWORDS_FOR_ADD_SEARCH_TERMS':
            if (state.searchForAdd.filters.searchTerms?.toLowerCase() === action.payload.searchTerms?.toLowerCase()) {
                return state;
            }
            return {
                ...state,
                searchForAdd: {
                    ...state.searchForAdd,
                    filters: {
                        ...state.searchForAdd.filters,
                        searchTerms: action.payload.searchTerms,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@KEYWORDS/SEARCH_KEYWORDS_FOR_ADD_CHANGE_PAGE':
            if (state.searchForAdd.filters.skip === action.payload.skip && state.searchForAdd.filters.take === action.payload.take) {
                return state;
            }
            return {
                ...state,
                searchForAdd: {
                    ...state.searchForAdd,
                    filters: {
                        ...state.searchForAdd.filters,
                        skip: action.payload.skip,
                        take: action.payload.take,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@KEYWORDS/CREATE_KEYWORD':
            return {
                ...state,
                createKeyword: {
                    ...state.createKeyword,
                    [action.payload.name]: {
                        didInvalidate: true,
                        isFetching: true,
                    },
                },
            };

        case '@KEYWORDS/CREATE_KEYWORD_SUCCESS':
            return {
                ...state,
                createKeyword: {
                    ...state.createKeyword,
                    [action.payload.name]: {
                        didInvalidate: false,
                        isFetching: false,
                        data: action.payload.response,
                    },
                },
                searchKeywords: {
                    ...state.searchKeywords,
                    didInvalidate: true,
                    isFetching: false,
                },
                keywordConfigs: {
                    ...state.keywordConfigs,
                    [action.payload.keywordId]: {
                        ...state.keywordConfigs[action.payload.keywordId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
                keywordSubscriptionConfigs: {
                    ...state.keywordSubscriptionConfigs,
                    [action.payload.keywordId]: {
                        ...state.keywordSubscriptionConfigs[action.payload.keywordId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@KEYWORDS/CREATE_KEYWORD_FAILURE':
            return {
                ...state,
                createKeyword: {
                    ...state.createKeyword,
                    [action.payload.name]: {
                        didInvalidate: false,
                        isFetching: false,
                    },
                },
            };

        case '@KEYWORDS/UPDATE_KEYWORD':
            return {
                ...state,
                updateKeyword: {
                    ...state.updateKeyword,
                    [action.payload.keywordId]: {
                        didInvalidate: true,
                        isFetching: true,
                    },
                },
            };

        case '@KEYWORDS/UPDATE_KEYWORD_SUCCESS':
            return {
                ...state,
                updateKeyword: {
                    ...state.updateKeyword,
                    [action.payload.keywordId]: {
                        didInvalidate: false,
                        isFetching: false,
                        data: action.payload.response,
                    },
                },
                searchKeywords: {
                    ...state.searchKeywords,
                    didInvalidate: true,
                    isFetching: false,
                },
                keywordConfigs: {
                    ...state.keywordConfigs,
                    [action.payload.keywordId]: {
                        ...state.keywordConfigs[action.payload.keywordId],
                        isFetching: false,
                        didInvalidate: true,
                        data: undefined,
                    },
                },
                keywordSubscriptionConfigs: {
                    ...state.keywordSubscriptionConfigs,
                    [action.payload.keywordId]: {
                        ...state.keywordSubscriptionConfigs[action.payload.keywordId],
                        isFetching: false,
                        didInvalidate: true,
                        data: undefined,
                    },
                },
            };

        case '@KEYWORDS/UPDATE_KEYWORD_FAILURE':
            return {
                ...state,
                updateKeyword: {
                    ...state.updateKeyword,
                    [action.payload.keywordId]:  {
                        didInvalidate: false,
                        isFetching: false,
                    },
                },
            };

        case '@KEYWORDS/DEACTIVATE_KEYWORD':
            return {
                ...state,
                deactivate: {
                    ...state.deactivate,
                    [action.payload.keywordId]: true,
                },
            };

        case '@KEYWORDS/DEACTIVATE_KEYWORD_SUCCESS':
            return {
                ...state,
                deactivate: {
                    ...state.deactivate,
                    [action.payload.keywordId]: false,
                },
                searchKeywords: {
                    ...state.searchKeywords,
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@KEYWORDS/DEACTIVATE_KEYWORD_FAILURE':
            return {
                ...state,
                deactivate: {
                    ...state.deactivate,
                    [action.payload.keywordId]: false,
                },
            };

        case '@KEYWORDS/REACTIVATE_KEYWORD':
            return {
                ...state,
                reactivate: {
                    ...state.reactivate,
                    [action.payload.keywordId]: true,
                },
            };

        case '@KEYWORDS/REACTIVATE_KEYWORD_SUCCESS':
            return {
                ...state,
                reactivate: {
                    ...state.reactivate,
                    [action.payload.keywordId]: false,
                },
                searchKeywords: {
                    ...state.searchKeywords,
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@KEYWORDS/REACTIVATE_KEYWORD_FAILURE':
            return {
                ...state,
                reactivate: {
                    ...state.reactivate,
                    [action.payload.keywordId]: false,
                },
            };

        case '@KEYWORDS/GET_KEYWORD_CONFIG':
            return {
                ...state,
                keywordConfigs: {
                    ...state.keywordConfigs,
                    [action.payload.keywordId]: {
                        ...state.keywordConfigs[action.payload.keywordId],
                        isFetching: true,
                        didInvalidate: false,
                    },
                },
            };

        case '@KEYWORDS/GET_KEYWORD_CONFIG_SUCCESS':
            return {
                ...state,
                keywordConfigs: {
                    ...state.keywordConfigs,
                    [action.payload.keywordId]: {
                        ...state.keywordConfigs[action.payload.keywordId],
                        isFetching: false,
                        didInvalidate: false,
                        data: action.payload.keywordConfig,
                    },
                },
            };

        case '@KEYWORDS/GET_KEYWORD_CONFIG_FAILURE':
            return {
                ...state,
                keywordConfigs: {
                    ...state.keywordConfigs,
                    [action.payload.keywordId]: {
                        ...state.keywordConfigs[action.payload.keywordId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@KEYWORDS/GET_KEYWORD_SUBSCRIPTION_CONFIG':
            return {
                ...state,
                keywordSubscriptionConfigs: {
                    ...state.keywordSubscriptionConfigs,
                    [action.payload.keywordId]: {
                        ...state.keywordSubscriptionConfigs[action.payload.keywordId],
                        isFetching: true,
                        didInvalidate: false,
                    },
                },
            };

        case '@KEYWORDS/GET_KEYWORD_SUBSCRIPTION_CONFIG_SUCCESS':
            return {
                ...state,
                keywordSubscriptionConfigs: {
                    ...state.keywordSubscriptionConfigs,
                    [action.payload.keywordId]: {
                        ...state.keywordSubscriptionConfigs[action.payload.keywordId],
                        isFetching: false,
                        didInvalidate: false,
                        data: action.payload.keywordSubscriptionConfig,
                    },
                },
            };

        case '@KEYWORDS/GET_KEYWORD_SUBSCRIPTION_CONFIG_FAILURE':
            return {
                ...state,
                keywordSubscriptionConfigs: {
                    ...state.keywordSubscriptionConfigs,
                    [action.payload.keywordId]: {
                        ...state.keywordSubscriptionConfigs[action.payload.keywordId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@KEYWORDS/FETCH_KEYWORDS':
            return {
                ...state,
                keywords: createFetchKeywordsState(
                    state,
                    action.payload.keywordIds,
                    {
                        isFetching: true,
                        didInvalidate: true,
                    }),
            };

        case '@KEYWORDS/FETCH_KEYWORDS_SUCCESS':
            return {
                ...state,
                keywords: createFetchKeywordsState(
                    state,
                    action.payload.keywordIds,
                    {
                        isFetching: false,
                        didInvalidate: false,
                    }),
            };

        case '@KEYWORDS/FETCH_KEYWORDS_FAILURE':
            return {
                ...state,
                keywords: createFetchKeywordsState(
                    state,
                    action.payload.keywordIds,
                    {
                        isFetching: false,
                        didInvalidate: true,
                    }),
            };

        default:
            return state;
    }
};


const createFetchKeywordsState = (state: KeywordsState, keywordIds: number[], apiRequest: ApiRequest) => {
    let stateKeywords = {
        ...state.keywords,
    };

    for (const keywordId of keywordIds) {
        stateKeywords = {
            ...stateKeywords,
            [keywordId]: {
                ...state.keywords[keywordId],
                ...apiRequest,
            },
        };
    }
    return stateKeywords;
};
