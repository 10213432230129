import { ServiceLoader } from 'components/common/Loading';
import { Layout } from 'components/Layout';
import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { RoutePaths } from './RoutePaths';
import { ProtectedRoute } from './ProtectedRoute';
import { Permissions } from 'services/RightsService';

const AllCompaniesLayout = lazy(() => import('components/Pages/AllCompanies/AllCompaniesLayout'));
const CreateCompanyLayout = lazy(() => import('components/Pages/AllCompanies/Create/CreateCompanyLayout'));
const AnalystsLayout = lazy(() => import('components/Pages/Analysts/AnalystsLayout'));
const CreateAnalystLayout = lazy(() => import('components/Pages/Analysts/Create/CreateAnalystLayout'));
const CreateProductLayout = lazy(() => import('components/Pages/Products/Create/CreateProductLayout'));
const GroupUsersLayout = lazy(() => import('components/Pages/Groups/GroupUsersLayout'));
const GroupsLayout = lazy(() => import('components/Pages/Groups/GroupsLayout'));
const KeywordsLayout = lazy(() => import('components/Pages/Keywords/KeywordsLayout'));
const NotAuthorized = lazy(() => import('components/Pages/Errors/NotAuthorized'));
const NotFound = lazy(() => import('components/Pages/Errors/NotFound'));
const ProductDistributionLayout = lazy(() => import('components/Pages/Products/Create/Distribution/ProductDistributionLayout'));
const ProductGeneralePropertiesLayout = lazy(() => import('components/Pages/Products/Create/GeneralePropertiesLayout'));
const ProductInsightLayout = lazy(() => import('components/Pages/Products/Create/Insight/ProductInsightLayout'));
const ProductPublicationLayout = lazy(() => import('components/Pages/Products/Create/Publication/PublicationLayout'));
const ProductsLayout = lazy(() => import('components/Pages/Products/ProductsLayout'));
const CategoriesLayout = lazy(() => import('components/Pages/Categories/CategoriesLayout'));
const CreateOrUpdateCategoryLayout = lazy(() => import('components/Pages/Categories/CreateOrUpdateCategoryLayout'));
const SendersLayout = lazy(() => import('components/Pages/Senders/SendersLayout'));
const UpdateAnalystLayout = lazy(() => import('components/Pages/Analysts/Update/UpdateAnalystLayout'));
const ComplianceLayout = lazy(() => import('components/Pages/AllCompanies/Compliance/ComplianceLayout'));

export const AppRoutes: React.FC = () => {
    React.useEffect(() => {
        if (window.location.href.includes('#') && !window.location.href.includes('/#access_token')) {
            window.location.href = window.location.href
                .replace(/\/#\//, '/')
                .replace(/\/#/, '/');
        }
    });

    return <Layout>
        <Suspense fallback={<div className="d-flex justify-content-center"><ServiceLoader /></div>}>
            <Routes>
                <Route path={RoutePaths.ResearchCompanies.AllCompanies.route} element={<ProtectedRoute><AllCompaniesLayout /></ProtectedRoute>} />
                <Route
                    path={RoutePaths.ResearchCompanies.AllCompanies.View.route}
                    element={<ProtectedRoute><CreateCompanyLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.ResearchCompanies.AllCompanies.Create.route}
                    element={<ProtectedRoute permissions={[Permissions.Administrator, Permissions.ManageCompanyDocumentData]}><CreateCompanyLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.ResearchCompanies.AllCompanies.Edit.route}
                    element={<ProtectedRoute permissions={[Permissions.Administrator, Permissions.ManageCompanyDocumentData]}><CreateCompanyLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.ResearchCompanies.AllCompanies.View.Compliance.route}
                    element={<ProtectedRoute><ComplianceLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.ResearchCompanies.AllCompanies.Edit.Compliance.route}
                    element={<ProtectedRoute permissions={[Permissions.Administrator, Permissions.ManageComplianceData]}><ComplianceLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.AuthorsAndGroups.Authors.route}
                    element={<ProtectedRoute permissions={[Permissions.Administrator]}><AnalystsLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.AuthorsAndGroups.Authors.Create.route}
                    element={<ProtectedRoute permissions={[Permissions.Administrator]}><CreateAnalystLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.AuthorsAndGroups.Authors.Update.route}
                    element={<ProtectedRoute permissions={[Permissions.Administrator]}><UpdateAnalystLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.AuthorsAndGroups.Groups.route}
                    element={<ProtectedRoute permissions={[Permissions.Administrator]}><GroupsLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.AuthorsAndGroups.Groups.Users.route}
                    element={<ProtectedRoute permissions={[Permissions.Administrator]}><GroupUsersLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.ProductsAndSubscriptions.Products.route}
                    element={<ProtectedRoute permissions={[Permissions.Administrator]}><ProductsLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.ProductsAndSubscriptions.Products.Create.route}
                    element={<ProtectedRoute permissions={[Permissions.Administrator]}><CreateProductLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.ProductsAndSubscriptions.Products.Create.GeneraleProperties.route}
                    element={<ProtectedRoute permissions={[Permissions.Administrator]}><ProductGeneralePropertiesLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.ProductsAndSubscriptions.Products.Create.Publication.route}
                    element={<ProtectedRoute permissions={[Permissions.Administrator]}><ProductPublicationLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.ProductsAndSubscriptions.Products.Create.Insight.route}
                    element={<ProtectedRoute permissions={[Permissions.Administrator]}><ProductInsightLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.ProductsAndSubscriptions.Products.Create.Distribution.route}
                    element={<ProtectedRoute permissions={[Permissions.Administrator]}><ProductDistributionLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.ProductsAndSubscriptions.Products.Update.route}
                    element={<ProtectedRoute permissions={[Permissions.Administrator]}><ProductGeneralePropertiesLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.ProductsAndSubscriptions.Products.Update.Publication.route}
                    element={<ProtectedRoute permissions={[Permissions.Administrator]}><ProductPublicationLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.ProductsAndSubscriptions.Products.Update.Insight.route}
                    element={<ProtectedRoute permissions={[Permissions.Administrator]}><ProductInsightLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.ProductsAndSubscriptions.Products.Update.Distribution.route}
                    element={<ProtectedRoute permissions={[Permissions.Administrator]}><ProductDistributionLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.ProductsAndSubscriptions.Senders.route}
                    element={<ProtectedRoute permissions={[Permissions.Administrator]}><SendersLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.ProductsAndSubscriptions.Keywords.route}
                    element={<ProtectedRoute permissions={[Permissions.Administrator]}><KeywordsLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.ProductsAndSubscriptions.ResearchCategories.route}
                    element={<ProtectedRoute permissions={[Permissions.Administrator]}><CategoriesLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.ProductsAndSubscriptions.ResearchCategories.Create.route}
                    element={<ProtectedRoute permissions={[Permissions.Administrator]}><CreateOrUpdateCategoryLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.ProductsAndSubscriptions.ResearchCategories.Update.route}
                    element={<ProtectedRoute permissions={[Permissions.Administrator]}><CreateOrUpdateCategoryLayout /></ProtectedRoute>}
                />
                <Route path={RoutePaths.Errors.NotAuthorized.route} element={<NotAuthorized />} />
                <Route path={RoutePaths.Errors.NotAuthorizedContactUs.route} element={<NotAuthorized isDisplayTryAgain={false} />} />
                <Route path={RoutePaths.Errors.NotFound.route} element={<ProtectedRoute><NotFound /></ProtectedRoute>} />
            </Routes>
        </Suspense>
    </Layout>;
};
