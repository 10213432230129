import { Reducer } from 'store';
const unloadedState: {
     [name: string]: {
        isLoading: boolean,
    };
} = {
} as const;

export type AsyncTypeaheadState = typeof unloadedState;

export const AsyncTypeaheadReducer: Reducer<AsyncTypeaheadState> = (state = unloadedState, action) => {
    switch (action.type) {
        case '@ASYNCTYPEAHEAD/SET_IS_LOADING_ASYNCTYPEAHEAD':
            return {
                ...state,
                [action.name]: {
                    ...state[action.name],
                    isLoading: action.isLoading,
                },
            };

        default:
            return state;
    }
};