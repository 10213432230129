import { ApiRequest, Reducer } from 'store';
import { Restriction } from 'services/ApiService/InsightBackoffice/InsightBackofficeApiClient';

export const initialState = {
    predefinedDisclosures: { isFetching: false, didInvalidate: false } as ApiRequest<number[] | undefined>,
    thirdPredefinedDisclosures: {} as { readonly [thirdId: number]: ApiRequest<number[]> },
    updateThirdPredefinedDisclosures: {} as { readonly [thirdId: number]: boolean },
    thirdRestrictions: {} as { readonly [thirdId: number]: ApiRequest<Restriction[]> },
    isDownloadingDisclosuresExport: false as boolean,
    isDownloadingRestrictionsExport: false as boolean,
} as const;

export type ComplianceState = typeof initialState;

export const ComplianceReducer: Reducer<ComplianceState> = (state = initialState, action) => {
    switch (action.type) {
        case '@COMPLIANCE/FETCH_PREDEFINED_DISCLOSURES':
            return {
                ...state,
                predefinedDisclosures: {
                    ...state.predefinedDisclosures,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@COMPLIANCE/FETCH_PREDEFINED_DISCLOSURES_SUCCESS':
            return {
                ...state,
                predefinedDisclosures: {
                    ...state.predefinedDisclosures,
                    data: action.payload.predefinedDisclosures,
                    isFetching: false,
                    didInvalidate: false,
                },
            };

        case '@COMPLIANCE/FETCH_PREDEFINED_DISCLOSURES_FAILURE':
            return {
                ...state,
                predefinedDisclosures: {
                    ...state.predefinedDisclosures,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@COMPLIANCE/FETCH_THIRD_PREDEFINED_DISCLOSURES':
            return {
                ...state,
                thirdPredefinedDisclosures: {
                    ...state.thirdPredefinedDisclosures,
                    [action.payload.thirdId]: {
                        ...state.thirdPredefinedDisclosures[action.payload.thirdId],
                        isFetching: true,
                        didInvalidate: true,
                    },
                },
            };

        case '@COMPLIANCE/FETCH_THIRD_PREDEFINED_DISCLOSURES_SUCCESS':
            return {
                ...state,
                thirdPredefinedDisclosures: {
                    ...state.thirdPredefinedDisclosures,
                    [action.payload.thirdId]: {
                        ...state.thirdPredefinedDisclosures[action.payload.thirdId],
                        isFetching: false,
                        didInvalidate: false,
                        data: action.payload.disclosures,
                    },
                },
            };

        case '@COMPLIANCE/FETCH_THIRD_PREDEFINED_DISCLOSURES_FAILURE':
            return {
                ...state,
                thirdPredefinedDisclosures: {
                    ...state.thirdPredefinedDisclosures,
                    [action.payload.thirdId]: {
                        ...state.thirdPredefinedDisclosures[action.payload.thirdId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@COMPLIANCE/UPDATE_THIRD_PREDEFINED_DISCLOSURES':
            return {
                ...state,
                updateThirdPredefinedDisclosures: {
                    ...state.updateThirdPredefinedDisclosures,
                    [action.payload.thirdId]: true,
                },
            };

        case '@COMPLIANCE/UPDATE_THIRD_PREDEFINED_DISCLOSURES_SUCCESS':
            return {
                ...state,
                updateThirdPredefinedDisclosures: {
                    ...state.updateThirdPredefinedDisclosures,
                    [action.payload.thirdId]: false,
                },
                thirdPredefinedDisclosures: {
                    [action.payload.thirdId]: {
                        ...state.thirdPredefinedDisclosures[action.payload.thirdId],
                        didInvalidate: true,
                        isFetching: false,
                    },
                },
            };

        case '@COMPLIANCE/UPDATE_THIRD_PREDEFINED_DISCLOSURES_FAILURE':
            return {
                ...state,
                updateThirdPredefinedDisclosures: {
                    ...state.updateThirdPredefinedDisclosures,
                    [action.payload.thirdId]: false,
                },
            };

        case '@COMPLIANCE/FETCH_THIRD_RESTRICTIONS':
            return {
                ...state,
                thirdRestrictions: {
                    ...state.thirdRestrictions,
                    [action.payload.thirdId]: {
                        ...state.thirdRestrictions[action.payload.thirdId],
                        isFetching: true,
                        didInvalidate: true,
                    },
                },
            };

        case '@COMPLIANCE/FETCH_THIRD_RESTRICTIONS_SUCCESS':
            return {
                ...state,
                thirdRestrictions: {
                    ...state.thirdRestrictions,
                    [action.payload.thirdId]: {
                        ...state.thirdRestrictions[action.payload.thirdId],
                        isFetching: false,
                        didInvalidate: false,
                        data: action.payload.restrictions,
                    },
                },
            };

        case '@COMPLIANCE/FETCH_THIRD_RESTRICTIONS_FAILURE':
            return {
                ...state,
                thirdRestrictions: {
                    ...state.thirdRestrictions,
                    [action.payload.thirdId]: {
                        ...state.thirdRestrictions[action.payload.thirdId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@COMPLIANCE/FETCH_DISCLOSURES_EXPORT':
            return {
                ...state,
                isDownloadingDisclosuresExport: true,
            };

        case '@COMPLIANCE/FETCH_DISCLOSURES_EXPORT_SUCCESS':
        case '@COMPLIANCE/FETCH_DISCLOSURES_EXPORT_FAILURE':
            return {
                ...state,
                isDownloadingDisclosuresExport: false,
            };

        case '@COMPLIANCE/FETCH_RESTRICTIONS_EXPORT':
            return {
                ...state,
                isDownloadingRestrictionsExport: true,
            };

        case '@COMPLIANCE/FETCH_RESTRICTIONS_EXPORT_SUCCESS':
        case '@COMPLIANCE/FETCH_RESTRICTIONS_EXPORT_FAILURE':
            return {
                ...state,
                isDownloadingRestrictionsExport: false,
            };

        default:
            return state;
    }
};
