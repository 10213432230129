import { ApiRequest, Reducer } from 'store';

export const initialState = {
    universes: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    categories: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    sectors: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    senders: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    countries: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    languages: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    workflows: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    visibilityGroups: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    focuses: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    disciplineTypes: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    researchApproaches: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    assetClasses: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    assetTypes: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    bloombergClasses: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    subjects: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    reutersPackageCodes: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    vendors: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    productCategories: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    titleRules: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    currencies: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    gicsSectors: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    categoryGroups: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    categoryNatures: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
    categoryAssetClasses: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<number[]>,
} as const;

export type ReferentialState = typeof initialState;

export const ReferentialReducer: Reducer<ReferentialState> = (state = initialState, action) => {
    switch (action.type) {
        case '@REFERENTIAL/FETCH_UNIVERSES':
            return {
                ...state,
                universes: {
                    ...state.universes,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_UNIVERSES_SUCCESS':
            return {
                ...state,
                universes: {
                    ...state.universes,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.ids,
                },
            };

        case '@REFERENTIAL/FETCH_UNIVERSES_FAILURE':
            return {
                ...state,
                universes: {
                    ...state.universes,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_CATEGORIES':
            return {
                ...state,
                categories: {
                    ...state.categories,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_CATEGORIES_SUCCESS':
            return {
                ...state,
                categories: {
                    ...state.categories,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.ids,
                },
            };

        case '@REFERENTIAL/FETCH_CATEGORIES_FAILURE':
            return {
                ...state,
                categories: {
                    ...state.categories,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_SECTORS':
            return {
                ...state,
                sectors: {
                    ...state.sectors,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_SECTORS_SUCCESS':
            return {
                ...state,
                sectors: {
                    ...state.sectors,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.ids,
                },
            };

        case '@REFERENTIAL/FETCH_SECTORS_FAILURE':
            return {
                ...state,
                sectors: {
                    ...state.sectors,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_SENDERS':
            return {
                ...state,
                senders: {
                    ...state.senders,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_SENDERS_SUCCESS':
            return {
                ...state,
                senders: {
                    ...state.senders,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.ids,
                },
            };

        case '@REFERENTIAL/FETCH_SENDERS_FAILURE':
            return {
                ...state,
                senders: {
                    ...state.senders,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_COUNTRIES':
            return {
                ...state,
                countries: {
                    ...state.countries,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_COUNTRIES_SUCCESS':
            return {
                ...state,
                countries: {
                    ...state.countries,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.ids,
                },
            };

        case '@REFERENTIAL/FETCH_COUNTRIES_FAILURE':
            return {
                ...state,
                countries: {
                    ...state.countries,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_LANGUAGES':
            return {
                ...state,
                languages: {
                    ...state.languages,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_LANGUAGES_SUCCESS':
            return {
                ...state,
                languages: {
                    ...state.languages,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.ids,
                },
            };

        case '@REFERENTIAL/FETCH_LANGUAGES_FAILURE':
            return {
                ...state,
                languages: {
                    ...state.languages,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_WORKFLOWS':
            return {
                ...state,
                workflows: {
                    ...state.workflows,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_WORKFLOWS_SUCCESS':
            return {
                ...state,
                workflows: {
                    ...state.workflows,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.ids,
                },
            };

        case '@REFERENTIAL/FETCH_WORKFLOWS_FAILURE':
            return {
                ...state,
                workflows: {
                    ...state.workflows,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_VISIBILITY_GROUPS':
            return {
                ...state,
                visibilityGroups: {
                    ...state.visibilityGroups,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_VISIBILITY_GROUPS_SUCCESS':
            return {
                ...state,
                visibilityGroups: {
                    ...state.visibilityGroups,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.ids,
                },
            };

        case '@REFERENTIAL/FETCH_VISIBILITY_GROUPS_FAILURE':
            return {
                ...state,
                visibilityGroups: {
                    ...state.visibilityGroups,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_FOCUSES':
            return {
                ...state,
                focuses: {
                    ...state.focuses,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_FOCUSES_SUCCESS':
            return {
                ...state,
                focuses: {
                    ...state.focuses,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.ids,
                },
            };

        case '@REFERENTIAL/FETCH_FOCUSES_FAILURE':
            return {
                ...state,
                focuses: {
                    ...state.focuses,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_DISCIPLINE_TYPES':
            return {
                ...state,
                disciplineTypes: {
                    ...state.disciplineTypes,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_DISCIPLINE_TYPES_SUCCESS':
            return {
                ...state,
                disciplineTypes: {
                    ...state.disciplineTypes,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.ids,
                },
            };

        case '@REFERENTIAL/FETCH_DISCIPLINE_TYPES_FAILURE':
            return {
                ...state,
                disciplineTypes: {
                    ...state.disciplineTypes,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_RESEARCH_APPROACHES':
            return {
                ...state,
                researchApproaches: {
                    ...state.researchApproaches,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_RESEARCH_APPROACHES_SUCCESS':
            return {
                ...state,
                researchApproaches: {
                    ...state.researchApproaches,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.ids,
                },
            };

        case '@REFERENTIAL/FETCH_RESEARCH_APPROACHES_FAILURE':
            return {
                ...state,
                researchApproaches: {
                    ...state.researchApproaches,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_ASSET_CLASSES':
            return {
                ...state,
                assetClasses: {
                    ...state.assetClasses,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_ASSET_CLASSES_SUCCESS':
            return {
                ...state,
                assetClasses: {
                    ...state.assetClasses,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.ids,
                },
            };

        case '@REFERENTIAL/FETCH_ASSET_CLASSES_FAILURE':
            return {
                ...state,
                assetClasses: {
                    ...state.assetClasses,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_ASSET_TYPES':
            return {
                ...state,
                assetTypes: {
                    ...state.assetTypes,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_ASSET_TYPES_SUCCESS':
            return {
                ...state,
                assetTypes: {
                    ...state.assetTypes,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.ids,
                },
            };

        case '@REFERENTIAL/FETCH_ASSET_TYPES_FAILURE':
            return {
                ...state,
                assetTypes: {
                    ...state.assetTypes,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_BLOOMBERG_CLASSES':
            return {
                ...state,
                bloombergClasses: {
                    ...state.bloombergClasses,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_BLOOMBERG_CLASSES_SUCCESS':
            return {
                ...state,
                bloombergClasses: {
                    ...state.bloombergClasses,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.ids,
                },
            };

        case '@REFERENTIAL/FETCH_BLOOMBERG_CLASSES_FAILURE':
            return {
                ...state,
                bloombergClasses: {
                    ...state.bloombergClasses,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_SUBJECTS':
            return {
                ...state,
                subjects: {
                    ...state.subjects,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_SUBJECTS_SUCCESS':
            return {
                ...state,
                subjects: {
                    ...state.subjects,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.ids,
                },
            };

        case '@REFERENTIAL/FETCH_SUBJECTS_FAILURE':
            return {
                ...state,
                subjects: {
                    ...state.subjects,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_REUTERS_PACKAGE_CODES':
            return {
                ...state,
                reutersPackageCodes: {
                    ...state.reutersPackageCodes,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_REUTERS_PACKAGE_CODES_SUCCESS':
            return {
                ...state,
                reutersPackageCodes: {
                    ...state.reutersPackageCodes,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.ids,
                },
            };

        case '@REFERENTIAL/FETCH_REUTERS_PACKAGE_CODES_FAILURE':
            return {
                ...state,
                reutersPackageCodes: {
                    ...state.reutersPackageCodes,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_VENDORS':
            return {
                ...state,
                vendors: {
                    ...state.vendors,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_VENDORS_SUCCESS':
            return {
                ...state,
                vendors: {
                    ...state.vendors,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.ids,
                },
            };

        case '@REFERENTIAL/FETCH_VENDORS_FAILURE':
            return {
                ...state,
                vendors: {
                    ...state.vendors,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_PRODUCT_CATEGORIES':
            return {
                ...state,
                productCategories: {
                    ...state.productCategories,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_PRODUCT_CATEGORIES_SUCCESS':
            return {
                ...state,
                productCategories: {
                    ...state.productCategories,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.ids,
                },
            };

        case '@REFERENTIAL/FETCH_PRODUCT_CATEGORIES_FAILURE':
            return {
                ...state,
                productCategories: {
                    ...state.productCategories,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_TITLE_RULES':
            return {
                ...state,
                titleRules: {
                    ...state.titleRules,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_TITLE_RULES_SUCCESS':
            return {
                ...state,
                titleRules: {
                    ...state.titleRules,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.ids,
                },
            };

        case '@REFERENTIAL/FETCH_TITLE_RULES_FAILURE':
            return {
                ...state,
                titleRules: {
                    ...state.titleRules,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_CURRENCIES':
            return {
                ...state,
                currencies: {
                    ...state.currencies,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_CURRENCIES_SUCCESS':
            return {
                ...state,
                currencies: {
                    ...state.currencies,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.ids,
                },
            };

        case '@REFERENTIAL/FETCH_CURRENCIES_FAILURE':
            return {
                ...state,
                currencies: {
                    ...state.currencies,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_CATEGORY_GROUPS':
            return {
                ...state,
                categoryGroups: {
                    ...state.categoryGroups,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_CATEGORY_GROUPS_SUCCESS':
            return {
                ...state,
                categoryGroups: {
                    ...state.categoryGroups,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.ids,
                },
            };

        case '@REFERENTIAL/FETCH_CATEGORY_GROUPS_FAILURE':
            return {
                ...state,
                categoryGroups: {
                    ...state.categoryGroups,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_CATEGORY_NATURES':
            return {
                ...state,
                categoryNatures: {
                    ...state.categoryNatures,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_CATEGORY_NATURES_SUCCESS':
            return {
                ...state,
                categoryNatures: {
                    ...state.categoryNatures,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.ids,
                },
            };

        case '@REFERENTIAL/FETCH_CATEGORY_NATURES_FAILURE':
            return {
                ...state,
                categoryNatures: {
                    ...state.categoryNatures,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_CATEGORY_ASSET_CLASSES':
            return {
                ...state,
                categoryAssetClasses: {
                    ...state.categoryAssetClasses,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_CATEGORY_ASSET_CLASSES_SUCCESS':
            return {
                ...state,
                categoryAssetClasses: {
                    ...state.categoryAssetClasses,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.ids,
                },
            };

        case '@REFERENTIAL/FETCH_CATEGORY_ASSET_CLASSES_FAILURE':
            return {
                ...state,
                categoryAssetClasses: {
                    ...state.categoryAssetClasses,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_ALL_GICS_SECTORS':
            return {
                ...state,
                gicsSectors: {
                    ...state.gicsSectors,
                    isFetching: true,
                    didInvalidate: true,
                },
            };

        case '@REFERENTIAL/FETCH_ALL_GICS_SECTORS_SUCCESS':
            return {
                ...state,
                gicsSectors: {
                    ...state.gicsSectors,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.ids,
                },
            };

        case '@REFERENTIAL/FETCH_ALL_GICS_SECTORS_FAILURE':
            return {
                ...state,
                gicsSectors: {
                    ...state.gicsSectors,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        default:
            return state;
    }
};
