import { SearchAnalystsRequest } from 'services/ApiService/InsightBackoffice/InsightBackofficeApiClient';
import { ApiRequest, Reducer } from 'store';

export const DEFAULT_SEARCH_ANALYSTS_FILTERS: SearchAnalystsRequest = {
    skip: 0,
    take: 15,
    onlyActive: true,
};

export const DEFAULT_SEARCH_BLUEMATRIXID_ANALYSTS_FILTERS: SearchAnalystsRequest = {
    skip: 0,
    take: 1,
    onlyActive: false,
};

export const initialState = {
    search: {
        filters: {
            ...DEFAULT_SEARCH_ANALYSTS_FILTERS,
        },
        total: 0,
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<string[]> & {
        filters: SearchAnalystsRequest,
        total: number,
    },
    searchForAdd: {} as {
        readonly [component: string]: ApiRequest<string[]> & {
            filters: SearchAnalystsRequest,
            total: number,
        }
    },
    searchAnalystBlueMatrix: {
        filters: {
            ...DEFAULT_SEARCH_BLUEMATRIXID_ANALYSTS_FILTERS,
        },
        total: 0,
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<string[]> & {
        filters: SearchAnalystsRequest,
        total: number,
    },
    creation: {} as { readonly [icId: string]: boolean },
    update: {} as { readonly [icId: string]: boolean },
    analysts: {} as { readonly [icId: string]: ApiRequest },
    deactivate: {} as { readonly [icId: string]: boolean },
    reactivate: {} as { readonly [icId: string]: boolean },
    isAnalystCandidates: {} as { readonly [icId: string]: ApiRequest<boolean> },
} as const;

export type AnalystsState = typeof initialState;

export const AnalystsReducer: Reducer<AnalystsState> = (state = initialState, action) => {
    let isAlreadyDefaultFilter: boolean;
    switch (action.type) {
        case '@ANALYSTS/SET_SEARCH_ANALYSTS_SEARCH_TERMS':
            if (state.search.filters.searchTerms?.toLowerCase() === action.payload.searchTerms?.toLowerCase()) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state.search.filters,
                        searchTerms: action.payload.searchTerms,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@ANALYSTS/SET_SEARCH_ANALYSTS_ONLY_ACTIVE':
            if (state.search.filters.onlyActive === action.payload.onlyActive) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state.search.filters,
                        onlyActive: action.payload.onlyActive,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@ANALYSTS/SEARCH_ANALYSTS_CHANGE_PAGE':
            if (state.search.filters.skip === action.payload.skip && state.search.filters.take === action.payload.take) {
                return state;
            }
            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...state.search.filters,
                        skip: action.payload.skip,
                        take: action.payload.take,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@ANALYSTS/RESET_SEARCH_ANALYSTS':
            isAlreadyDefaultFilter = Object.entries(state.search.filters).every(([filterKey, filterValue]) => {
                switch (filterKey) {
                    case 'skip':
                        return filterValue === 0;
                    case 'take':
                        return filterValue === DEFAULT_SEARCH_ANALYSTS_FILTERS.take;
                    case 'onlyActive':
                        return filterValue === DEFAULT_SEARCH_ANALYSTS_FILTERS.onlyActive;
                    default:
                        return !filterValue;
                }
            });
            if (isAlreadyDefaultFilter) {
                return state;
            }

            return {
                ...state,
                search: {
                    ...state.search,
                    filters: {
                        ...DEFAULT_SEARCH_ANALYSTS_FILTERS,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@ANALYSTS/SEARCH_ANALYSTS':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: true,
                },
            };

        case '@ANALYSTS/SEARCH_ANALYSTS_SUCCESS':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: false,
                    isFetching: false,
                    data: action.payload.analystIds,
                    total: action.payload.total,
                },
                analysts: createFetchAnalystsState(
                    state,
                    action.payload.analystIds,
                    {
                        isFetching: false,
                        didInvalidate: false,
                    }),
            };

        case '@ANALYSTS/SEARCH_ANALYSTS_FAILURE':
            return {
                ...state,
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@ANALYSTS/CREATE_ANALYST':
            return {
                ...state,
                creation: {
                    ...state.creation,
                    [action.payload.icId]: true,
                },
            };

        case '@ANALYSTS/CREATE_ANALYST_SUCCESS':
        case '@ANALYSTS/CREATE_ANALYST_FAILURE':
            return {
                ...state,
                creation: {
                    ...state.creation,
                    [action.payload.icId]: false,
                },
            };

        case '@ANALYSTS/UPDATE_ANALYST':
            return {
                ...state,
                update: {
                    ...state.update,
                    [action.payload.icId]: true,
                },
            };

        case '@ANALYSTS/UPDATE_ANALYST_SUCCESS':
            return {
                ...state,
                update: {
                    ...state.update,
                    [action.payload.icId]: false,
                },
                analysts: {
                    ...state.analysts,
                    [action.payload.icId]: {
                        didInvalidate: true,
                        isFetching: false,
                    },
                },
            };

        case '@ANALYSTS/UPDATE_ANALYST_FAILURE':
            return {
                ...state,
                update: {
                    ...state.update,
                    [action.payload.icId]: false,
                },
            };

        case '@ANALYSTS/FETCH_ANALYSTS':
            return {
                ...state,
                analysts: createFetchAnalystsState(
                    state,
                    action.payload.icIds,
                    {
                        isFetching: true,
                        didInvalidate: true,
                    }),
            };

        case '@ANALYSTS/FETCH_ANALYSTS_SUCCESS':
            return {
                ...state,
                analysts: createFetchAnalystsState(
                    state,
                    action.payload.icIds,
                    {
                        isFetching: false,
                        didInvalidate: false,
                    }),
            };

        case '@ANALYSTS/FETCH_ANALYSTS_FAILURE':
            return {
                ...state,
                analysts: createFetchAnalystsState(
                    state,
                    action.payload.icIds,
                    {
                        isFetching: false,
                        didInvalidate: true,
                    }),
            };

        case '@ANALYSTS/DEACTIVATE_ANALYST':
            return {
                ...state,
                deactivate: {
                    ...state.deactivate,
                    [action.payload.icId]: true,
                },
            };

        case '@ANALYSTS/DEACTIVATE_ANALYST_SUCCESS':
            return {
                ...state,
                deactivate: {
                    ...state.deactivate,
                    [action.payload.icId]: false,
                },
                analysts: createFetchAnalystsState(
                    state,
                    [action.payload.icId],
                    {
                        isFetching: false,
                        didInvalidate: true,
                    }),
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@ANALYSTS/DEACTIVATE_ANALYST_FAILURE':
            return {
                ...state,
                deactivate: {
                    ...state.deactivate,
                    [action.payload.icId]: false,
                },
            };

        case '@ANALYSTS/REACTIVATE_ANALYST':
            return {
                ...state,
                reactivate: {
                    ...state.reactivate,
                    [action.payload.icId]: true,
                },
            };

        case '@ANALYSTS/REACTIVATE_ANALYST_SUCCESS':
            return {
                ...state,
                reactivate: {
                    ...state.reactivate,
                    [action.payload.icId]: false,
                },
                analysts: createFetchAnalystsState(
                    state,
                    [action.payload.icId],
                    {
                        isFetching: false,
                        didInvalidate: true,
                    }),
                search: {
                    ...state.search,
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        case '@ANALYSTS/REACTIVATE_ANALYST_FAILURE':
            return {
                ...state,
                reactivate: {
                    ...state.reactivate,
                    [action.payload.icId]: false,
                },
            };

        case '@ANALYSTS/INVALIDATE_ANALYST':
            return {
                ...state,
                analysts: {
                    ...state.analysts,
                    [action.payload.icId]: {
                        ...state.analysts[action.payload.icId],
                        didInvalidate: true,
                    },
                },
            };

        case '@ANALYSTS/SEARCH_ANALYSTS_FOR_ADD':
            return {
                ...state,
                searchForAdd: {
                    ...state.searchForAdd,
                    [action.payload.component]: {
                        ...state.searchForAdd[action.payload.component],
                        didInvalidate: true,
                        isFetching: true,
                    },
                },
            };

        case '@ANALYSTS/SEARCH_ANALYSTS_FOR_ADD_SUCCESS':
            return {
                ...state,
                searchForAdd: {
                    ...state.searchForAdd,
                    [action.payload.component]: {
                        ...state.searchForAdd[action.payload.component],
                        didInvalidate: false,
                        isFetching: false,
                        data: action.payload.analystIds,
                        total: action.payload.total,
                    },
                },
                analysts: createFetchAnalystsState(
                    state,
                    action.payload.analystIds,
                    {
                        isFetching: false,
                        didInvalidate: false,
                    }),
            };

        case '@ANALYSTS/SEARCH_ANALYSTS_FOR_ADD_FAILURE':
            return {
                ...state,
                searchForAdd: {
                    ...state.searchForAdd,
                    [action.payload.component]: {
                        ...state.searchForAdd[action.payload.component],
                        didInvalidate: true,
                        isFetching: false,
                    },
                },
            };

        case '@ANALYSTS/SET_SEARCH_ANALYSTS_FOR_ADD_SEARCH_TERMS':
            if (state.searchForAdd[action.payload.component].filters.searchTerms?.toLowerCase() === action.payload.searchTerms?.toLowerCase()) {
                return state;
            }
            return {
                ...state,
                searchForAdd: {
                    ...state.searchForAdd,
                    [action.payload.component]: {
                        ...state.searchForAdd[action.payload.component],
                        filters: {
                            ...state.searchForAdd[action.payload.component].filters,
                            searchTerms: action.payload.searchTerms,
                            skip: 0,
                        },
                        didInvalidate: true,
                        isFetching: false,
                    },
                },
            };

        case '@ANALYSTS/SEARCH_ANALYSTS_FOR_ADD_CHANGE_PAGE':
            if (state.searchForAdd[action.payload.component].filters.skip === action.payload.skip && state.searchForAdd[action.payload.component].filters.take === action.payload.take) {
                return state;
            }
            return {
                ...state,
                searchForAdd: {
                    ...state.searchForAdd,
                    [action.payload.component]: {
                        ...state.searchForAdd[action.payload.component],
                        filters: {
                            ...state.searchForAdd[action.payload.component].filters,
                            skip: action.payload.skip,
                            take: action.payload.take,
                        },
                        didInvalidate: true,
                        isFetching: false,
                    },
                },
            };

        case '@ANALYSTS/INIT_SEARCH_ANALYSTS_FOR_ADD':
            return {
                ...state,
                searchForAdd: {
                    ...state.searchForAdd,
                    [action.payload.component]: {
                        filters: {
                            ...DEFAULT_SEARCH_ANALYSTS_FILTERS,
                        },
                        total: 0,
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@ANALYSTS/FETCH_IS_ANALYST_CANDIDATE':
            return {
                ...state,
                isAnalystCandidates: {
                    ...state.isAnalystCandidates,
                    [action.payload.icId]: {
                        isFetching: true,
                        didInvalidate: true,
                    },
                },
            };

        case '@ANALYSTS/FETCH_IS_ANALYST_CANDIDATE_SUCCESS':
            return {
                ...state,
                isAnalystCandidates: {
                    ...state.isAnalystCandidates,
                    [action.payload.icId]: {
                        isFetching: false,
                        didInvalidate: false,
                        data: action.payload.isAnalystCandidate,
                    },
                },
            };

        case '@ANALYSTS/FETCH_IS_ANALYST_CANDIDATE_FAILURE':
            return {
                ...state,
                isAnalystCandidates: {
                    ...state.isAnalystCandidates,
                    [action.payload.icId]: {
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        case '@ANALYSTS/SEARCH_ANALYSTS_BLUEMATRIXID':
            return {
                ...state,
                searchAnalystBlueMatrix: {
                    ...state.searchAnalystBlueMatrix,
                    didInvalidate: true,
                    isFetching: true,
                },
            };

        case '@ANALYSTS/SEARCH_ANALYSTS_BLUEMATRIXID_SUCCESS':
            return {
                ...state,
                searchAnalystBlueMatrix: {
                    ...state.searchAnalystBlueMatrix,
                    didInvalidate: false,
                    isFetching: false,
                    data: action.payload.analystIds,
                    total: action.payload.total,
                },
                analysts: createFetchAnalystsState(
                    state,
                    action.payload.analystIds,
                    {
                        isFetching: false,
                        didInvalidate: false,
                    }),
            };

        case '@ANALYSTS/SEARCH_ANALYSTS_BLUEMATRIXID_FAILURE':
            return {
                ...state,
                searchAnalystBlueMatrix: {
                    ...state.searchAnalystBlueMatrix,
                    didInvalidate: true,
                    isFetching: false,
                },
            };

        default:
            return state;
    }
};

const createFetchAnalystsState = (state: AnalystsState, icIds: string[], apiRequest: ApiRequest) => {
    let stateAnalysts = {
        ...state.analysts,
    };
    for (const icId of icIds) {
        stateAnalysts = {
            ...stateAnalysts,
            [icId]: {
                ...state.analysts[icId],
                ...apiRequest,
            },
        };
    }
    return stateAnalysts;
};

